<!-- <div id="info__Mission__2" class="info__section--background-image--light"
    style="background-image: url('/assets/images/banner-bg.jpeg'); ">
    <div class="info__content--single-column">
        <h1 class="info__title--single-column" style="color: white">Our Mission to Reduce Waste</h1>
        <div class="info__paragraph--markdown">
            <p>MadKraftz is commited to save more than just furniture. To lovingly deliver an artisan collection of
                uniquely
                designed pieces and curated experiences to make your space better, while being light to the planet.</p>

        </div>
        
    </div>
</div> -->


<div id="mission-section" class="mission-bg">
<div class="py-5 mission">
    <div class="container">
        <div class="row m-0">
            <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12 d-flex justify-content-start">
                <div class="row">
                    <h1 class="col-12 title align-self-center text-start">OUR MISSION TO<br />REDUCE WASTE</h1>
                    <div class="d-flex justify-content-start p-0">
                        <div class="align-self-start">
                            <a class="landing-button--ghost landing-button" routerLink="/about"
                                fragment="mission" href="/about#mission">LEARN MORE</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-7 col-lg-7 col-md-6 col-sm-12 d-flex align-items-center">
                <p class="landing_text-light">MadKraftz is commited to save more than just furniture. To lovingly deliver an artisan collection of
                    uniquely designed pieces and curated experiences to make your space better, while being light to the
                    planet.</p>
            </div>
        </div></div>
    </div>
</div>