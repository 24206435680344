<div class="left-navi">
    <a class="left-navi-link" [ngClass]="infoPageTitle==='About'?'selected':''" routerLink="/about" href="/about" title="About">
        <div class="left-navi-link-icon ss-info"></div>
        <div class="left-navi-link-text">About</div>
    </a>
    <a class="left-navi-link" [ngClass]="infoPageTitle==='How it works'?'selected':''" routerLink="/how_it_works/buyer"
        href="/how_it_works/buyer" title="How it works">
        <div class="left-navi-link-icon ss-signpost"></div>
        <div class="left-navi-link-text">How it works</div>
    </a>
    <a class="left-navi-link" [ngClass]="infoPageTitle==='Privacy policy'?'selected':''" routerLink="/privacy"
        href="/privacy" title="Privacy policy">
        <div class="left-navi-link-icon ss-lockfile"></div>
        <div class="left-navi-link-text">Privacy policy</div>
    </a>
    <a class="left-navi-link" [ngClass]="infoPageTitle==='Terms of use'?'selected':''" routerLink="/terms" href="/terms"
        title="Terms of use">
        <div class="left-navi-link-icon ss-textfile"></div>
        <div class="left-navi-link-text">Terms of use</div>
    </a>
</div>
<div class="left-navi-dropdown toggle with-borders hidden-tablet" [ngClass]="!isMenuOut?'':'toggled' "
    data-toggle=".left-navi-menu" (click)="toggleMenu()">
    <div class="icon-with-text ss-lockfile"></div>
    <div class="text-with-icon">{{infoPageTitle}}</div>
    <i class="icon-dropdown ss-dropdown"></i>
</div>
<div class="left-navi-menu toggle-menu" [ngClass]="isMenuOut?'':'hidden'">
    <a routerLink="/about" href="/about" id="about_left_navi_link" title="About">
        <div class="icon-with-text ss-info"></div>
        <div class="text-with-icon">About</div>
    </a>
    <a routerLink="/how_it_works/buyer" href="/how_it_works/buyer" id="how_to_use_left_navi_link" title="How it works">
        <div class="icon-with-text ss-signpost"></div>
        <div class="text-with-icon">How it works</div>
    </a>
    <a routerLink="/privacy" href="/privacy" id="privacy_left_navi_link" title="Privacy policy">
        <div class="icon-with-text ss-lockfile"></div>
        <div class="text-with-icon">Privacy policy</div>
    </a>
    <a routerLink="/terms" href="/terms" id="terms_left_navi_link" title="Terms of use">
        <div class="icon-with-text ss-textfile"></div>
        <div class="text-with-icon">Terms of use</div>
    </a>
</div>