import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit {
// Public
public coreConfig: any;
public passwordTextType!: boolean;
public registerForm!: FormGroup;
public submitted = false;

// Private
private _unsubscribeAll: Subject<any>;

/**
 * Constructor
 *
 * @param {CoreConfigService} _coreConfigService
 * @param {FormBuilder} _formBuilder
 */
constructor(private _formBuilder: FormBuilder) {
  this._unsubscribeAll = new Subject();
}

// convenience getter for easy access to form fields
get f() {
  return this.registerForm.controls;
}

/**
 * Toggle password
 */
togglePasswordTextType() {
  this.passwordTextType = !this.passwordTextType;
}

/**
 * On Submit
 */
onSubmit() {
  this.submitted = true;

  // stop here if form is invalid
  if (this.registerForm.invalid) {
    return;
  }
}

// Lifecycle Hooks
// -----------------------------------------------------------------------------------------------------

/**
 * On init
 */
ngOnInit(): void {
  this.registerForm = this._formBuilder.group({
    username: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required]
  });

}

/**
 * On destroy
 */
ngOnDestroy(): void {
  // Unsubscribe from all subscriptions
  this._unsubscribeAll.complete();
}
}
