<section class="marketplace-lander">
  <div class="coverimage">
    <figure class="marketplace-cover-small fluidratio"></figure>
    <div class="coverimage-fade">
      <figure class="marketplace-cover-small-fade fluidratio"></figure>
    </div>
  </div>
  <div class="title-container">
    <div class="title-header-wrapper">
      <div class="marketplace-title-header">
        <h1>Create a new MadKraftz account</h1>

      </div>
    </div>
  </div>
</section>
<article class="page-content">
  <div class="wrapper">

    <div class="signup-form centered-section">
      <form class="new_person" id="new_person" action="/en/people" accept-charset="UTF-8" method="post"
        novalidate="novalidate"><input name="utf8" type="hidden" value="✓"><input type="hidden"
          name="authenticity_token"
          value="4Ux2x62TmucV6yWf69E20jZ/in9zu8YbP1gDgV9FQ3tKOnceZwrgJLhA/dGh6FvxFFHGFFo/8Oql7rA6ZOLJTQ==">
        <div class="inline-label-container">
          <label class="inline" for="invitation_code">Invitation code</label>
          <a tabindex="-1" id="help_invitation_code_link" class="label-info" href="#">What's this?</a>
        </div>
        <input type="text" name="invitation_code" id="invitation_code" value="" class="text_field" maxlength="20">
        <label class="unwanted_text_field" for="person_input_again">You should not see this field, if CSS is working. If
          you see this, please send feedback!</label>
        <input class="unwanted_text_field" id="error_regristration_unwanted_email2" autocomplete="off" type="text"
          name="person[input_again]">
        <label for="person_email">Email address</label>
        <input class="text_field error" maxlength="255" size="255" type="text" name="person[email]" id="person_email"
          aria-invalid="false"><label id="person_email-error" class="error" for="person_email">The email you gave is
          already in use.</label>
        <label for="person_given_name">First name</label>
        <input class="text_field" maxlength="30" size="30" type="text" name="person[given_name]" id="person_given_name">
        <label for="person_family_name">Last name</label>
        <input class="text_field" maxlength="30" size="30" type="text" name="person[family_name]"
          id="person_family_name">
        <label for="person_password">Password</label>
        <input class="text_field valid" maxlength="255" id="person_password1" size="255" type="password"
          name="person[password]" aria-invalid="false">
        <label for="person_password2">Confirm password</label>
        <input class="text_field" maxlength="255" size="255" type="password" name="person[password2]"
          id="person_password2">
        <div class="inline-label-container">
          <label class="inline" for="person_custom_fields_0">Delivery</label>
          <small>(This field will be publicly visible)</small>
        </div>
        <input type="hidden" name="person[custom_field_values_attributes][][id]"
          id="person_custom_field_values_attributes__id">
        <input type="hidden" name="person[custom_field_values_attributes][][type]"
          id="person_custom_field_values_attributes__type" value="TextFieldValue">
        <input type="hidden" name="person[custom_field_values_attributes][][custom_field_id]"
          id="person_custom_field_values_attributes__custom_field_id" value="181210">
        <input type="text" name="person[custom_field_values_attributes][][text_value]" id="person_custom_fields_0"
          class="required" input_group_id="person_custom_fields_0">

        <div class="inline-label-container">
          <label class="inline" for="person_custom_fields_1">Shop Policy</label>
          <small>(This field will be publicly visible)</small>
        </div>
        <input type="hidden" name="person[custom_field_values_attributes][][id]"
          id="person_custom_field_values_attributes__id">
        <input type="hidden" name="person[custom_field_values_attributes][][type]"
          id="person_custom_field_values_attributes__type" value="TextFieldValue">
        <input type="hidden" name="person[custom_field_values_attributes][][custom_field_id]"
          id="person_custom_field_values_attributes__custom_field_id" value="181206">
        <input type="text" name="person[custom_field_values_attributes][][text_value]" id="person_custom_fields_1"
          class="required" input_group_id="person_custom_fields_1">

        <div class="inline-label-container">
          <label class="inline" for="person_custom_fields_2">Return and Exchange policy</label>
          <small>(This field will be publicly visible)</small>
        </div>
        <input type="hidden" name="person[custom_field_values_attributes][][id]"
          id="person_custom_field_values_attributes__id">
        <input type="hidden" name="person[custom_field_values_attributes][][type]"
          id="person_custom_field_values_attributes__type" value="TextFieldValue">
        <input type="hidden" name="person[custom_field_values_attributes][][custom_field_id]"
          id="person_custom_field_values_attributes__custom_field_id" value="181208">
        <input type="text" name="person[custom_field_values_attributes][][text_value]" id="person_custom_fields_2"
          class="required" input_group_id="person_custom_fields_2">

        <div class="inline-label-container">
          <label class="inline" for="person_custom_fields_3">VAT ID</label>
          <small>(This field will be publicly visible)</small>
        </div>
        <input type="hidden" name="person[custom_field_values_attributes][][id]"
          id="person_custom_field_values_attributes__id">
        <input type="hidden" name="person[custom_field_values_attributes][][type]"
          id="person_custom_field_values_attributes__type" value="TextFieldValue">
        <input type="hidden" name="person[custom_field_values_attributes][][custom_field_id]"
          id="person_custom_field_values_attributes__custom_field_id" value="181209">
        <input type="text" name="person[custom_field_values_attributes][][text_value]" id="person_custom_fields_3"
          class="required" input_group_id="person_custom_fields_3">

        <div class="inline-label-container"></div>
        <div class="outer-checkbox-container">
          <div class="checkbox-container">
            <input id="person_terms" name="person[terms]" type="checkbox">
            <label class="checkbox" for="person_terms">I accept the <a tabindex="-1" id="terms_link" class="form"
                href="#">Terms of use</a> and <a tabindex="-1" id="privacy_link" class="form" href="#">Privacy
                policy</a>
            </label>
          </div>
        </div>
        <input value="SHARETRIBE1.0" type="hidden" name="person[consent]" id="person_consent">
        <div class="outer-checkbox-container">
          <div class="checkbox-container">
            <input id="person_admin_emails_consent" name="person[admin_emails_consent]" type="checkbox">
            <label class="checkbox" for="person_admin_emails_consent">I agree to receive occasional emails from the
              MadKraftz team and understand that I can change my mind at any time</label>
          </div>
        </div>
        <button name="button" type="submit">Create account</button>
      </form>
    </div>
    <div class="lightbox" id="terms">
      <div class="lightbox-content">
        <a class="close lightbox-x" href="#" id="close_x">
          <i class="ss-delete"></i>
        </a>
        <div data-mercury="full" id="terms_page_content">
          <span style="background-color:#FFFFFF"></span>
          <h1>
            <font color="#ff6600">MadKraftz Terms of Use</font>
          </h1>
          <p></p>
          <p>MadKraftz is a web service built on the Sharetribe platform. The general terms of Sharetribe below apply also
            to the use of MadKraftz</p>
          <p>Sharetribe is a social media service that allows its users to change favors and items and communicate with
            each other. The Sharetribe-service may only be used in accordance with these terms of use. The service
            provider reserves the right to change these terms of use if required. Valid terms of use can be found from
            Sharetribe’s website.</p>
          <p><span style="color: rgb(23, 23, 23); font-size: 1em;"><br></span></p>
          <h2><span style="font-size: 1em;">
              <font color="#009900">Rights of Content</font>
            </span></h2>
          <p>
            The users themselves retain the right to all text, pictures and other content that they create in the
            service. The users allow others to utilize the content in accordance with the nature of the service and
            furthermore allow the service provider to file information and data and make changes that are necessary for
            the service or the study, however other rights are not transferred from the users, unless specifically
            otherwise agreed. The responsibility of the content lies with the user, who has produced it to the service.
            The service provider has the right to remove any material when it deems it necessary.
          </p>
          <h3>Disclaimer</h3>
          <p>
            No guarantees of the functioning of the Sharetribe service are given. The users are themselves responsible
            for their actions in the service and they should estimate the reliability of other users before dealing with
            them. The service provider can under no circumstances be liable for damage that is caused to the user. The
            user may not store any information or data in the service, and expect it to remain there.
          </p>
          <h3>The Removal of a User</h3>
          <p>
            The service provider has the right to remove any users from Sharetribe and terminate their right of use of
            the service without any specific reason and without being liable for compensation.
          </p>
          <h3>Applicable Jurisdiction</h3>
          <p>
            The jurisdiction that is applicable in this service and these terms of use is that of Finland, unless
            something else is required by binding law.</p>
          <p></p>

        </div>




      </div>
    </div>
    <div class="lightbox" id="help_invitation_code">
      <div class="lightbox-content">
        <a class="close lightbox-x" href="#" id="close_x">
          <i class="ss-delete"></i>
        </a>
        <h2>
          <div class="hidden_title">
            You need an invite to join
          </div>
        </h2>
        <p>
          To join
          <strong>
            MadKraftz
          </strong>
          you need to have an invite. If you have an invite, please enter the code in the box below.
        </p>
        <p>
          By using invitation codes we try to make sure that all MadKraftz users are trustworthy parties.
        </p>


      </div>
    </div>
    <div class="lightbox" id="privacy">
      <div class="lightbox-content">
        <a class="close lightbox-x" href="#" id="close_x">
          <i class="ss-delete"></i>
        </a>
        <h3>
          Registrar
        </h3>
        <p>
          MadKraftz service: Sharetribe Ltd
          <br>
          <a href="http://www.sharetribe.com/en">www.sharetribe.com</a>
          <br>
          info (at) sharetribe.com
        </p>
        <h3>
          Name of the register
        </h3>
        <p>
          User register of the MadKraftz service
        </p>
        <h3>
          Use of personal details (purpose of register)
        </h3>
        <p>
          Personal details are collected to make communication and use of service possible. Details can be used for
          communication between service providers and users and also for direct communication between users. Some
          personal details are visible on the profile page of the user, but those details are voluntary (except name).
        </p>
        <p>
          The handling of personal details is not outsourced, but the register data is stored on a server that is rented
          from a third party company.
        </p>
        <h3>
          Information content of the register
        </h3>
        <p>
          The following information may be stored in the register:
        </p>
        <ul>
          <li>
            Personal details: Name, email address, phone number, street address
          </li>
          <li>
            Account details: username, password (stored in encrypted format)
          </li>
          <li>
            The description text that the user may write about him/herself
          </li>
          <li>
            The offers and requests the user has posted to the service
          </li>
          <li>
            The given and received feedback and badges
          </li>
          <li>
            Statistical data about service usage, e.g. number times the user has logged in
          </li>
        </ul>
        <p></p>
        <h3>
          Regular sources of information
        </h3>
        <p>
          Personal details are given by the user on registration to the service or when using it later.
        </p>
        <h3>
          Regular handovers of the information
        </h3>
        <p>
          The information may be handed over for research purposes as described in the
          <a href="/en/infos/terms">Terms of use</a>
          that the user accepts before starting to use the service. The researchers may not publish any research results
          so that identifying information would be revealed or that any specific user could be detected.
        </p>
        <p>
          Information considering users of a single MadKraftz community may be handed over to the client who has founded
          that community or to the community administrators appointed by that client.
        </p>
        <h3>
          Transfers of the information outside the EU and the European Economic Area
        </h3>
        <p>
          Information may be stored to a server that may be located inside or outside of the EU and the European
          Economic Area
        </p>
        <h3>
          Register protection principles
        </h3>
        <p>
          The information is stored on computers. The access to the information is restricted with passwords and
          physical access to the computers is restricted by the server hosting company.
        </p>




      </div>
    </div>


  </div>
</article>