<section class="marketplace-lander">
    <div class="coverimage">
        <figure class="marketplace-cover-small fluidratio"></figure>
        <div class="coverimage-fade">
            <figure class="marketplace-cover-small-fade fluidratio"></figure>
        </div>
    </div>
    <div class="title-container">
        <div class="title-header-wrapper">
            <div class="marketplace-title-header">
                <h1>Information about MadKraftz</h1>
            </div>
        </div>
    </div>
</section>
<article class="page-content">
    <div class="wrapper">
        <app-left-menu></app-left-menu>
        <div class="left-navi-section about-section">
            <div data-mercury="full" id="privacy_page_content">
                <!-- <h3>
                    Registrar
                </h3>
                <p>
                    MadKraftz service: Sharetribe Ltd
                    <br>
                    <a href="http://www.sharetribe.com/en">www.sharetribe.com</a>
                    <br>
                    info (at) sharetribe.com
                </p> -->
                <h1>
                    <font color="#0a7951">MadKraftz Privacy Policy</font>
                </h1>
                <h2>
                    <font color="#ff6600">Use of personal details (purpose of register)</font>
                </h2>
                <p>
                    Personal details are collected to make communication and use of service possible. Details can be
                    used for communication between service providers and users and also for direct communication between
                    users. Some personal details are visible on the profile page of the user, but those details are
                    voluntary (except name).
                </p>
                <p>
                    The handling of personal details is not outsourced, but the register data is stored on a server that
                    is rented from a third party company.
                </p>
                <h2>
                    <font color="#ff6600">
                        Information content of the register
                    </font>
                </h2>
                <p>
                    The following information may be stored in the register:
                </p>
                <ul>
                    <li>
                        Personal details: Name, email address, phone number, street address
                    </li>
                    <li>
                        Account details: username, password (stored in encrypted format)
                    </li>
                    <li>
                        The description text that the user may write about him/herself
                    </li>
                    <li>
                        The offers and requests the user has posted to the service
                    </li>
                    <li>
                        The given and received feedback and badges
                    </li>
                    <li>
                        Statistical data about service usage, e.g. number times the user has logged in
                    </li>
                </ul>
                <p></p>
                <h2>
                    <font color="#ff6600">
                        Regular sources of information</font>
                </h2>
                <p>
                    Personal details are given by the user on registration to the service or when using it later.
                </p>
                <h2>
                    <font color="#ff6600">
                        Regular handovers of the information
                    </font>
                </h2>
                <p>
                    The information may be handed over for research purposes as described in the
                    <a href="/terms" routerLink="/terms">Terms of use</a>
                    that the user accepts before starting to use the service. The researchers may not publish any
                    research results so that identifying information would be revealed or that any specific user could
                    be detected.
                </p>
                <p>
                    Information considering users of a single MadKraftz community may be handed over to the client who has
                    founded that community or to the community administrators appointed by that client.
                </p>
                <h2>
                    <font color="#ff6600">
                        Register protection principles
                    </font>
                </h2>
                <p style="padding-bottom: 50px;">
                    The information is stored on computers. The access to the information is restricted with passwords
                    and physical access to the computers is restricted by the server hosting company.
                </p>
            </div>
        </div>
    </div>
</article>