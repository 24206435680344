<div class="dark-bg pt-4">
    <div class="container">
        <div class="row pb-5 m-0">
            <div class="col-lg-12 col-sm-12 col-12 d-flex justify-content-end">
                <h1 class="title align-self-center text-end">HOW CAN WE<br />HELP YOU?</h1>
            </div>
            <!-- <hr style="border: 0.1px solid #d4dfff"> -->
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 pb-4">
                <div class="help-single">
                    <div class="help-inner d-flex flex-column">
                        <!-- <svg width="48px" height="48px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <defs>
                                <style>
                                    .a {
                                        fill: none;
                                        stroke: currentColor;
                                        stroke-linecap: round;
                                        stroke-linejoin: round
                                    }
                                </style>
                            </defs>
                            <circle class="a" cx="11.137" cy="11.137" r="5.137"></circle>
                            <path class="a" d="M14.769 14.769 18 18"></path>
                            <circle class="a" cx="12" cy="12" r="11.5"></circle>
                        </svg> -->
                        <h3 class="help-title">Buy<br />Online</h3>

                        <!-- /.help-title -->
                        <p class="landing_text-light">Simply browse our already selected items listed and shop the ones that complete your grand design. You might like loads
                        of items and don't know what to choose to best fit your space. Just message us and we'll offer free interior design
                        advice to make your life easier!</p>
                        <!-- /.landing_text-light -->
                        <div class="d-flex justify-content-center mt-auto">
                            <a class="landing-button--ghost landing-button" href="{{shopUrl}}/">SHOP
                                NOW</a>
                        </div>
                    </div><!-- /.help-inner -->
                </div><!-- /.help-single -->
            </div><!-- /.col-lg-3 -->
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 pb-4">
                <div class="help-single">
                    <div class="help-inner d-flex flex-column" style="background-color: #22503f">
                        <!-- <svg width="48px" height="48px" id="Light" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: none;
                                        stroke: currentColor;
                                        stroke-linecap: round;
                                        stroke-linejoin: round
                                    }
                                </style>
                            </defs>
                            <rect class="cls-1" x=".5" y="4.5" width="23" height="14" rx="1" ry="1"></rect>
                            <path class="cls-1"
                                d="m3 18.5.4 4a1.106 1.106 0 0 0 2.2 0l.4-4M21 18.5l-.4 4a1.106 1.106 0 0 1-2.2 0l-.4-4M.5 10.5h23M10.5 7.5h3M18.5 2.5a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V1a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5z">
                            </path>
                            <rect class="cls-1" x="2.5" y="12.5" width="2" height="6" rx=".5" ry=".5"></rect>
                            <rect class="cls-1" x="4.5" y="12.5" width="2" height="6" rx=".5" ry=".5"></rect>
                            <rect class="cls-1" x="6.5" y="12.5" width="2" height="6" rx=".5" ry=".5"></rect>
                            <rect class="cls-1" x="9.864" y="12.678" width="2" height="6" rx=".5" ry=".5"
                                transform="rotate(-35.685 10.864 15.677)"></rect>
                        </svg> -->
                        <h3 class="help-title">Bespoke<br />Orders</h3>

                        <!-- /.help-title -->
                        <p class="landing_text-light">You have a keepsake that you want to refinish or upcycle and match what you currently own. We will request some photos
                        of your space and give you a few options to choose from. Just send us a message, we're here to help.</p><!-- /.landing_text-light -->
                        <div class="d-flex justify-content-center mt-auto">
                            <a class="landing-button--ghost landing-button" routerLink="/how_it_works/buyer"
                                href="/how_it_works/creator">LEARN MORE</a>
                        </div>
                    </div><!-- /.help-inner -->
                </div><!-- /.help-single -->
            </div><!-- /.col-lg-3 -->
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 pb-4">
                <div class="help-single">
                    <div class="help-inner d-flex flex-column" style="background-color: #285c49">
                        <!-- <svg width="48px" height="48px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <defs>
                                <style>
                                    .a {
                                        fill: none;
                                        stroke: currentColor;
                                        stroke-linecap: round;
                                        stroke-linejoin: round
                                    }
                                </style>
                            </defs>
                            <path class="a"
                                d="M.5 23.5h23M1.5 23.5v-7M12.5 16.5v7M5.5 23.5v-4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v4M23.5 17a3.775 3.775 0 0 1-4 3.5 3.775 3.775 0 0 1-4-3.5c0-1.652 2.921-8.513 3.771-10.474a.249.249 0 0 1 .458 0C20.579 8.487 23.5 15.348 23.5 17zM19.5 13.438V23.5M18 17l1.5 1.5M.5 17.5l6.146-6.146a.5.5 0 0 1 .708 0L13.5 17.5M6.485 3.2A2 2 0 1 1 8 6.5H3.5a3 3 0 1 1 2.985-3.3">
                            </path>
                        </svg> -->
                        <h3 class="help-title">The Whole Kit<br />and Caboodle</h3>
                        <!-- /.help-title -->
                        <p class="landing_text-light">You wish to redesign your home or your business (B&amp;B,
                            Restaurant, Art Gallery, Themed
                            Events etc).
                            Send us a message or give us a ring, we offer bespoke interior design services with
                            options
                            to reuse what
                            you already have in the space!</p><!-- /.landing_text-light -->
                        <div class="d-flex justify-content-center mt-auto">
                            <a class="landing-button--ghost landing-button"
                                href="{{shopUrl}}/en/user_feedbacks/new">CONTACT
                                US</a>
                        </div>
                    </div><!-- /.help-inner -->
                </div><!-- /.help-single -->
            </div><!-- /.col-lg-3 -->
        </div>
    </div>
</div>


<!-- 


<section id="info__How-does-it-work__6" class="info__section--zebra">
    <div class="info__content--multi-column">
        <h1 class="info__title--multi-column">How are we able to help?</h1>


        <div class="info__columns">
            <div class="info__column--three-columns">
                <div class="info__column-text-content">
                    <div class="info__column-icon info__How-does-it-work__6__info__column-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <defs>
                                <style>
                                    .a {
                                        fill: none;
                                        stroke: currentColor;
                                        stroke-linecap: round;
                                        stroke-linejoin: round
                                    }
                                </style>
                            </defs>
                            <circle class="a" cx="11.137" cy="11.137" r="5.137"></circle>
                            <path class="a" d="M14.769 14.769 18 18"></path>
                            <circle class="a" cx="12" cy="12" r="11.5"></circle>
                        </svg>
                    </div>
                    <h2 class="info__column-title">Buy Upcycled</h2>

                    <div class="info__column-paragraph--markdown">
                        <p>Simply browse our already upcycled items listed and choose the ones that complete your grand
                            design. You
                            might like a lot of items on our website and don't know what to choose to best fit your
                            space. Just send
                            us a message and we'll offer free interior design advice to make your life easier!</p>

                    </div>
                </div>
                <a class="landing-button--ghost landing-button" href="{{shopUrl}}/">Shop
                    now</a>
            </div>
            <div class="info__column--three-columns">
                <div class="info__column-text-content">
                    <div class="info__column-icon info__How-does-it-work__6__info__column-icon">
                        <svg id="Light" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: none;
                                        stroke: currentColor;
                                        stroke-linecap: round;
                                        stroke-linejoin: round
                                    }
                                </style>
                            </defs>
                            <rect class="cls-1" x=".5" y="4.5" width="23" height="14" rx="1" ry="1"></rect>
                            <path class="cls-1"
                                d="m3 18.5.4 4a1.106 1.106 0 0 0 2.2 0l.4-4M21 18.5l-.4 4a1.106 1.106 0 0 1-2.2 0l-.4-4M.5 10.5h23M10.5 7.5h3M18.5 2.5a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V1a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5z">
                            </path>
                            <rect class="cls-1" x="2.5" y="12.5" width="2" height="6" rx=".5" ry=".5"></rect>
                            <rect class="cls-1" x="4.5" y="12.5" width="2" height="6" rx=".5" ry=".5"></rect>
                            <rect class="cls-1" x="6.5" y="12.5" width="2" height="6" rx=".5" ry=".5"></rect>
                            <rect class="cls-1" x="9.864" y="12.678" width="2" height="6" rx=".5" ry=".5"
                                transform="rotate(-35.685 10.864 15.677)"></rect>
                        </svg>
                    </div>
                    <h2 class="info__column-title">Bespoke Orders</h2>

                    <div class="info__column-paragraph--markdown">
                        <p>You have a keepsake that you want to upcycle and match what you currently own. We will
                            request some
                            photos of your space and give you a few options to choose from. Just send us a message,
                            we're here to
                            help.</p>

                    </div>
                </div>

                <a class="landing-button--ghost landing-button"
                    routerLink="/how_it_works/buyer" href="/how_it_works/creator">Learn more</a>
            </div>
            <div class="info__column--three-columns">
                <div class="info__column-text-content">
                    <div class="info__column-icon info__How-does-it-work__6__info__column-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <defs>
                                <style>
                                    .a {
                                        fill: none;
                                        stroke: currentColor;
                                        stroke-linecap: round;
                                        stroke-linejoin: round
                                    }
                                </style>
                            </defs>
                            <path class="a"
                                d="M.5 23.5h23M1.5 23.5v-7M12.5 16.5v7M5.5 23.5v-4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v4M23.5 17a3.775 3.775 0 0 1-4 3.5 3.775 3.775 0 0 1-4-3.5c0-1.652 2.921-8.513 3.771-10.474a.249.249 0 0 1 .458 0C20.579 8.487 23.5 15.348 23.5 17zM19.5 13.438V23.5M18 17l1.5 1.5M.5 17.5l6.146-6.146a.5.5 0 0 1 .708 0L13.5 17.5M6.485 3.2A2 2 0 1 1 8 6.5H3.5a3 3 0 1 1 2.985-3.3">
                            </path>
                        </svg>
                    </div>
                    <h2 class="info__column-title">The Whole Kit and Caboodle</h2>

                    <div class="info__column-paragraph--markdown">
                        <p>You wish to redesign your home or your business (B&amp;B, Restaurant, Art Gallery, Themed
                            Events etc).
                            Send us a message or give us a ring, we offer bespoke interior design services with options
                            to reuse what
                            you already have in the space!</p>

                    </div>
                </div>

                <a class="landing-button--ghost landing-button"
                    href="{{shopUrl}}/en/user_feedbacks/new">Contact Us</a>
            </div>
        </div>
    </div>
</section> -->