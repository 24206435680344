<!-- 
<section id="video__Presentation-Video__4" class="categories__section--blank">
    <div class="video__youtube-player-container--centering container">

    <h1 class="categories__title text-center">Our Presentation Video</h1>
        <div
            class="video__Presentation-Video__4__video__youtube-player-container--aspect-ratio video__youtube-player-container--aspect-ratio videoWrapper">

            <iframe id="video__Presentation-Video__4__video__youtube-player" class="video__youtube-player"
                frameborder="0" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title="YouTube video player" width="640" height="480"
                src="https://www.youtube.com/embed/k85CLlfTpyE?controls=0&amp;modestbranding=1&amp;rel=0&amp;showinfo=0&amp;autoplay=0&amp;loop=1&amp;enablejsapi=1&amp;widgetid=1"></iframe>
            <div id="video__Presentation-Video__4__video__youtube-overlay"
                class="video__youtube-overlay video__youtube-overlay--inactive video__youtube-overlay--hover-animation">
            </div>
            <div id="video__Presentation-Video__4__video__youtube-controls" class="video__youtube-controls">
                <svg id="video__Presentation-Video__4__video__youtube-watch-video-icon"
                    class="video__youtube-watch-video-icon" width="63" height="63" viewBox="47 -1 63 63"
                    xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="#FFF"
                        stroke-width="2">
                        <path
                            d="M108 30c0 16.57-13.433 30-30 30-16.57 0-30-13.43-30-30S61.43 0 78 0c16.567 0 30 13.43 30 30z">
                        </path>
                        <path d="M68.87 41.74V18.26L92.347 30z"></path>
                    </g>
                </svg>
                <span id="video__Presentation-Video__4__video__youtube-watch-video-text"
                    class="video__youtube-watch-video-text">Our presentation video</span>
            </div>
        </div>
    </div>
</section> -->

<div class="light-bg">
    <div class="container">
        <div class="row m-0 py-3">
            <div class="col-lg-12 col-sm-12 col-12 d-flex">
                <h1 class="title align-self-center">OUR PRESENTATION VIDEO</h1>
            </div>
        </div>
    </div>
    <div class="plyr">
        <!-- <iframe width="560" height="349"
            src="https://www.youtube.com/embed/v6nulrF2jNc?controls=0&amp;modestbranding=1&amp;rel=0&amp;showinfo=0&amp;autoplay=0&amp;loop=1&amp;enablejsapi=1&amp;widgetid=1"
            frameborder="0" frameborder="0"
            allow="accelerometer; encrypted-media; clipboard-write; gyroscope; picture-in-picture" webkitallowfullscreen
            mozallowfullscreen allowfullscreen></iframe> -->
        <plyr id="plyr" style="display: block;" [style.width]="plyrWidthStyle"
            [style.height]="plyrHeightStyle" plyrTitle="Video 1" [plyrSources]="videoSources"
            (plyrInit)="player = $event" (plyrPlay)="played($event)" (plyrPause)="paused($event)"></plyr>
    </div>
</div>