import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.css']
})
export class LeftMenuComponent implements OnInit {

  isMenuOut!: boolean;
  infoPageTitle!: string;
  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.startsWith('/about')) {
          this.infoPageTitle = 'About';
        } else if (event.url.startsWith('/how_it_works')) {
          this.infoPageTitle = 'How it works';
        } else if (event.url === '/privacy') {
          this.infoPageTitle = 'Privacy policy';
        } else if (event.url === '/terms') {
          this.infoPageTitle = 'Terms of use';
        }
      }
    });
  }

  ngOnInit(): void {
    this.isMenuOut = false;
  }

  toggleMenu() {
    this.isMenuOut = !this.isMenuOut;
  }

}
