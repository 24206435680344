import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css', './../information-styles.css']
})
export class TermsComponent implements OnInit {

  isMenuOut!: boolean;
  constructor() { }

  ngOnInit(): void {
    this.isMenuOut = false;
  }

  toggleMenu() {
    this.isMenuOut = !this.isMenuOut;
  }

}
