<section class="marketplace-lander">
    <div class="coverimage">
        <figure class="marketplace-cover-small fluidratio"></figure>
        <div class="coverimage-fade">
            <figure class="marketplace-cover-small-fade fluidratio"></figure>
        </div>
    </div>
    <div class="title-container">
        <div class="title-header-wrapper">
            <div class="marketplace-title-header">
                <h1>Post a new listing</h1>

            </div>
        </div>
    </div>
</section>
<article *ngIf="category===undefined" class="page-content">
    <div class="wrapper">

        <div class="new-listing-form centered-section" id="new_listing_form">
            <div id="selected-groups">
                <div class="selected-group" name="category">
                    <a class="select selected hidden" data-id="624082">
                        <div class="link-text">Category: Furniture</div>
                    </a>
                    <a class="select selected hidden" data-id="668438">
                        <div class="link-text">Category: Lighting</div>
                    </a>
                    <a class="select selected hidden" data-id="624262">
                        <div class="link-text">Category: Gifts and Decorations</div>
                    </a>
                </div>
                <div class="selected-group" name="listing_shape">
                    <a class="select selected hidden" data-id="275127">
                        <div class="link-text">Listing type: Selling Beautiful upcycled furniture</div>
                    </a>
                </div>
            </div>
            <h2 class="listing-form-title" id="foo">Select category</h2>
            <div id="option-groups">
                <div class="option-group" name="category">
                    <a class="select option" data-id="624082" (click)="selectedCategory('furniture')">
                        <div class="link-text">Furniture</div>
                    </a>
                    <a class="select option" data-id="668438" (click)="selectedCategory('lighting')">
                        <div class="link-text">Lighting</div>
                    </a>
                    <a class="select option" data-id="624262" (click)="selectedCategory('gifts')">
                        <div class="link-text">Gifts and Decorations</div>
                    </a>
                </div>
                <div class="option-group" name="listing_shape">
                    <a class="select option hidden" data-id="275127">
                        <div class="link-text">Selling Beautiful upcycled furniture</div>
                    </a>
                </div>
            </div>
            <div class="js-form-fields hidden"></div>
        </div>

    </div>
</article>
<article *ngIf="category!==undefined" class="page-content">
    <div class="wrapper">

        <div class="new-listing-form centered-section" id="new_listing_form">
            <div id="selected-groups">
                <div class="selected-group" name="category">
                    <a class="select selected hidden" data-id="624082">
                        <div class="link-text">Category: Furniture</div>
                    </a>
                    <a class="select selected hidden" data-id="668438">
                        <div class="link-text">Category: Lighting</div>
                    </a>
                    <a class="select selected" data-id="624262">
                        <div class="link-text">Category: Gifts and Decorations</div>
                    </a>
                </div>
                <div class="selected-group" name="listing_shape">
                    <a class="select selected hidden" data-id="275127">
                        <div class="link-text">Listing type: Selling Beautiful upcycled furniture</div>
                    </a>
                </div>
            </div>
            <h2 class="listing-form-title" id="foo"></h2>
            <div id="option-groups">
                <div class="option-group" name="category">
                    <a class="select option hidden" data-id="624082">
                        <div class="link-text">Furniture</div>
                    </a>
                    <a class="select option hidden" data-id="668438">
                        <div class="link-text">Lighting</div>
                    </a>
                    <a class="select option hidden" data-id="624262">
                        <div class="link-text">Gifts and Decorations</div>
                    </a>
                </div>
                <div class="option-group" name="listing_shape">
                    <a class="select option hidden" data-id="275127">
                        <div class="link-text">Selling Beautiful upcycled furniture</div>
                    </a>
                </div>
            </div>
            <div class="js-form-fields">
                <script>
                    //<![CDATA[
                    ST.loadTranslations({ "listings.form.images.processing": "Processing...", "listings.form.images.this_may_take_a_while": "this only takes a second", "listings.form.images.percentage_loaded": "%{percentage}%", "listings.form.images.uploading_failed": "Image uploading failed", "listings.form.images.file_too_large": "The file is too large", "listings.form.images.image_processing_failed": "Image processing failed", "listings.form.images.accepted_formats": "The image format must be either GIF, JPG or PNG.", "listings.form.images.loading_image": "Loading...", "listings.form.images.select_file": "Select file", "listings.form.images.removing": "Removing...", "listings.form.images.add_more": "+ Add more" })
//]]>
                </script>
                <script>
                    $(document).ready(function () {
                        var listingImageOpts = { "s3UploadPath": "https://sharetribe-uploads.s3.amazonaws.com/", "s3Fields": { "key": "uploads/listing-images/2022/6/20220605T0521Z-48e3c534cefd608d3652c9c5848fdf26/${index}/${filename}", "acl": "public-read", "policy": "eyJleHBpcmF0aW9uIjoiMjAyMi0wNi0wNVQxNToyMTowNVoiLCJjb25kaXRpb25zIjpbWyJzdGFydHMtd2l0aCIsIiRrZXkiLCJ1cGxvYWRzL2xpc3RpbmctaW1hZ2VzLyJdLFsic3RhcnRzLXdpdGgiLCIkQ29udGVudC1UeXBlIiwiaW1hZ2UvIl0sWyJzdGFydHMtd2l0aCIsIiRzdWNjZXNzX2FjdGlvbl9zdGF0dXMiLCIyMDAiXSxbImNvbnRlbnQtbGVuZ3RoLXJhbmdlIiwwLCIxNjc3NzIxNiJdLHsiYnVja2V0Ijoic2hhcmV0cmliZS11cGxvYWRzIn0seyJhY2wiOiJwdWJsaWMtcmVhZCJ9XX0=", "signature": "n/U1jHDa1JMj/LMTCEf+0CXTWsw=", "AWSAccessKeyId": "AKIAJHBVAOMJFVBBJ7MQ", "success_action_status": 200 }, "saveFromFile": "/en/listing_images/add_from_file", "saveFromUrl": "/en/listing_images/add_from_url", "maxImageFilesize": "16777216", "originalImageWidth": "1600", "originalImageHeight": "1600", "reorderUrl": null }
                        var listingImages = []

                        window.ST.initialize_new_listing_form("No file selected", "Select file", "en", "You must select one.", "This date must be between current time and 6 months from now.", "false", "true", "Price must be a whole number.", "50", "100", "Minimum price is 0.50 GBP.", [], listingImages, listingImageOpts, "All images have not finished uploading. Do you really want to continue?");
                    });
                </script>


                <form class="new_listing js-listing-form-ready" id="new_listing" enctype="multipart/form-data"
                    action="/en/listings" accept-charset="UTF-8" method="post" novalidate="novalidate"><input
                        name="utf8" type="hidden" value="✓"><input type="hidden" name="authenticity_token"
                        value="dzKr8GgPGNi6ioR3MH3RUD+9Z85FpAb8ENetAfyS9RDjYZDh1kRsl1n5Ihqysu9R/8Mrh9zNxE8VdTtkXgL9hQ=="><label
                        class="input" for="listing_title">Listing title*</label>
                    <input class="title_text_field" maxlength="65" size="65" type="text" name="listing[title]"
                        id="listing_title">

                    <div class="price-container">
                        <label for="listing_price">Price</label>
                        <input class="price-field" maxlength="10" placeholder="0" value="0" size="10" type="text"
                            name="listing[price]" id="listing_price">
                        <div class="price-currency">
                            £
                        </div>

                        <small>
                            Once someone makes an order, you'll receive this amount minus <a
                                class="listing-payment-fee-info-link" href="#" id="payment_fee_info_link">
                                MadKraftz fee and a payment processing fee
                            </a>

                        </small>
                    </div>
                    <div class="lightbox" id="payment_fee_info_content">
                        <div class="lightbox-content">
                            <a class="close lightbox-x" href="#" id="close_x">
                                <i class="ss-delete"></i>
                            </a>
                            <h2>Commission and Payment processing fee</h2>
                            <p>Payments in MadKraftz are processed by the payment gateway listed below. MadKraftz will
                                charge a commission depending on the payment gateway. The payment gateway may also
                                charge a fee for each payment, as described below.</p>
                            <h4>PayPal</h4>
                            For PayPal payments, MadKraftz charges a commission of 15%. The minimum transaction fee per
                            transaction is £0.40. PayPal's fees are not included in this commission, so in addition to
                            MadKraftz commission, you will also pay PayPal's fees for each. PayPal's fee is between 2% and
                            5% of the total sales price, depending on your monthly sales volume and the country of
                            residence of the buyer. In general, domestic purchases have lower fees than international
                            ones, and higher monthly sales give you a discount on fees.<p></p>
                            <p>You can see the exact fees by logging in to your PayPal account and going <a
                                    target="_blank"
                                    href="https://www.paypal.com/cgi-bin/marketingweb?cmd=_display-xborder-fees-outside">here</a>.
                                After each purchase you will get a receipt displaying the exact fee.</p>
                            <p>

                            </p>
                        </div>
                    </div>
                    <script>
                        $('#payment_fee_info_link').click(function () { $('#payment_fee_info_content').lightbox_me({ centered: true, zIndex: 1000000 }); return false });
                    </script>

                    <div class="delivery-options-container">
                        <fieldset class="delivery-title">
                            <legend>Delivery method</legend>
                            <div class="delivery-row-low">
                                <div class="delivery-checkbox">
                                    <input type="checkbox" name="listing[delivery_methods][]" id="shipping-checkbox"
                                        value="shipping" class="delivery-method-checkbox required">
                                </div>
                                <div class="delivery-left-field">
                                    <label class="shipping-options-label" for="shipping-checkbox">Shipping</label>
                                </div>
                            </div>
                            <div class="js-shipping-price-container" style="display: none;">
                                <div class="delivery-row-tall">
                                    <div class="delivery-left-field shipping-price-container shipping-price-default">
                                        <div class="delivery-row">
                                            <div class="delivery-label-cont">
                                                <label class="shipping-options-label"
                                                    for="listing_shipping_price">Shipping fee</label>
                                            </div>
                                            <div class="delivery-input-cont">
                                                <div class="delivery-currency">
                                                    <div class="price-currency">
                                                        £
                                                    </div>

                                                </div>
                                                <input class="price-field delivery-price-field" maxlength="10"
                                                    placeholder="0" value="0" size="10" type="text"
                                                    name="listing[shipping_price]" id="listing_shipping_price">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="delivery-row-tall">
                                        <div
                                            class="delivery-left-field hidden js-shipping-price-additional shipping-price-container">
                                            <div class="delivery-row">
                                                <div class="delivery-label-cont">
                                                    <label class="shipping-options-label"
                                                        for="listing_shipping_price_additional">Additional items</label>
                                                </div>
                                                <div class="delivery-input-cont">
                                                    <div class="delivery-currency">
                                                        <div class="price-currency">
                                                            £
                                                        </div>

                                                    </div>
                                                    <input class="price-field delivery-price-field" maxlength="10"
                                                        placeholder="0" value="0" size="10" type="text"
                                                        name="listing[shipping_price_additional]"
                                                        id="listing_shipping_price_additional">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="delivery-row-low">
                                <div class="delivery-checkbox">
                                    <input type="checkbox" name="listing[delivery_methods][]" id="pickup-checkbox"
                                        value="pickup" class="delivery-method-checkbox required">
                                </div>
                                <div class="delivery-left-field">
                                    <label class="shipping-options-label" for="pickup-checkbox">Pickup</label>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <label class="input" for="listing_description">Detailed description</label>
                    <div class="info-text-container">
                        <div class="info-text-icon">
                            <i class="ss-info"></i>
                        </div>
                        <div class="info-text-content">
                            <p>You can <a id="markdown-popup-link" href="#">format your description using Markdown</a>.
                                If your description contains YouTube links, the videos will be shown below the
                                description.</p>
                        </div>
                    </div>

                    <textarea maxlength="5000" class="listing_description_textarea" name="listing[description]"
                        id="listing_description"
                        style="overflow: hidden; overflow-wrap: break-word; resize: horizontal; height: 120px;"></textarea>

                    <div class="new_listing_form_field_container">
                        <label for="custom_fields_183771">Diameter</label>
                        <input type="text" name="custom_fields[183771]" id="custom_fields_183771" value="" class="">

                        <label for="custom_fields_171436">Height</label>
                        <input type="text" name="custom_fields[171436]" id="custom_fields_171436" value="" class="">

                        <label for="custom_fields_171437">Width</label>
                        <input type="text" name="custom_fields[171437]" id="custom_fields_171437" value="" class="">

                        <label for="custom_fields_171439">Depth</label>
                        <input type="text" name="custom_fields[171439]" id="custom_fields_171439" value="" class="">

                        <label for="custom_fields_171442">Weight</label>
                        <input type="text" name="custom_fields[171442]" id="custom_fields_171442" value="" class="">

                        <label for="custom_fields_183772">Cable lenght</label>
                        <input type="text" name="custom_fields[183772]" id="custom_fields_183772" value="" class="">

                        <label for="custom_fields_183826">Colour</label>
                        <div class="row checkbox-group-container">
                            <div class="col-6">
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[183826][]"
                                        id="custom_fields_183826_669997" value="669997" class="custom_field_checkbox ">
                                    <label class="light" for="custom_fields_183826_669997">White</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[183826][]"
                                        id="custom_fields_183826_669998" value="669998" class="custom_field_checkbox ">
                                    <label class="light" for="custom_fields_183826_669998">Black</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[183826][]"
                                        id="custom_fields_183826_669999" value="669999" class="custom_field_checkbox ">
                                    <label class="light" for="custom_fields_183826_669999">Blue</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[183826][]"
                                        id="custom_fields_183826_670000" value="670000" class="custom_field_checkbox ">
                                    <label class="light" for="custom_fields_183826_670000">Red</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[183826][]"
                                        id="custom_fields_183826_670001" value="670001" class="custom_field_checkbox ">
                                    <label class="light" for="custom_fields_183826_670001">Green</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[183826][]"
                                        id="custom_fields_183826_670011" value="670011" class="custom_field_checkbox ">
                                    <label class="light" for="custom_fields_183826_670011">Gold</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[183826][]"
                                        id="custom_fields_183826_670012" value="670012" class="custom_field_checkbox ">
                                    <label class="light" for="custom_fields_183826_670012">Silver</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[183826][]"
                                        id="custom_fields_183826_670013" value="670013" class="custom_field_checkbox ">
                                    <label class="light" for="custom_fields_183826_670013">Yellow</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[183826][]"
                                        id="custom_fields_183826_670014" value="670014" class="custom_field_checkbox ">
                                    <label class="light" for="custom_fields_183826_670014">Orange</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[183826][]"
                                        id="custom_fields_183826_670015" value="670015" class="custom_field_checkbox ">
                                    <label class="light" for="custom_fields_183826_670015">Pink</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[183826][]"
                                        id="custom_fields_183826_670016" value="670016" class="custom_field_checkbox ">
                                    <label class="light" for="custom_fields_183826_670016">Lavander</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[183826][]"
                                        id="custom_fields_183826_670017" value="670017" class="custom_field_checkbox ">
                                    <label class="light" for="custom_fields_183826_670017">Purple</label>
                                </div>
                            </div>
                        </div>

                        <label for="custom_fields_183828">Secondary color / Pattern</label>
                        <select class="" name="custom_fields[183828]" id="custom_fields_183828">
                            <option value="">Select one...</option>
                            <option value="670018">Jungle</option>
                            <option value="670019">Rock 'n Roll</option>
                            <option value="670020">Flamingos</option>
                        </select>

                        <label for="custom_fields_171383">Product/materials origin*</label>
                        <div class="row checkbox-group-container">
                            <div class="col-6">
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171383][]"
                                        id="custom_fields_171383_624420" value="624420"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171383_624420">Upcycled</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171383][]"
                                        id="custom_fields_171383_624419" value="624419"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171383_624419">Refurbished</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171383][]"
                                        id="custom_fields_171383_624421" value="624421"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171383_624421">Recycled</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171383][]"
                                        id="custom_fields_171383_669093" value="669093"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171383_669093">Reclaimed</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171383][]"
                                        id="custom_fields_171383_669210" value="669210"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171383_669210">Antique</label>
                                </div>
                            </div>
                        </div>

                        <label for="custom_fields_171385">Re-used materials*</label>
                        <div class="row checkbox-group-container">
                            <div class="col-6">
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171385][]"
                                        id="custom_fields_171385_624431" value="624431"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171385_624431">Metal</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171385][]"
                                        id="custom_fields_171385_669990" value="669990"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171385_669990">Brass</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171385][]"
                                        id="custom_fields_171385_669991" value="669991"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171385_669991">Copper</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171385][]"
                                        id="custom_fields_171385_669996" value="669996"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171385_669996">Bakelite</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171385][]"
                                        id="custom_fields_171385_669992" value="669992"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171385_669992">Silver</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171385][]"
                                        id="custom_fields_171385_669993" value="669993"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171385_669993">Sterling silver</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171385][]"
                                        id="custom_fields_171385_669994" value="669994"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171385_669994">Gold</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171385][]"
                                        id="custom_fields_171385_669995" value="669995"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171385_669995">Whilte Gold</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171385][]"
                                        id="custom_fields_171385_624432" value="624432"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171385_624432">Wood</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171385][]"
                                        id="custom_fields_171385_624433" value="624433"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171385_624433">Glass</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171385][]"
                                        id="custom_fields_171385_624434" value="624434"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171385_624434">Paper</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171385][]"
                                        id="custom_fields_171385_624435" value="624435"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171385_624435">Cardboard</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171385][]"
                                        id="custom_fields_171385_624436" value="624436"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171385_624436">Cork</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171385][]"
                                        id="custom_fields_171385_624437" value="624437"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171385_624437">Rubber</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171385][]"
                                        id="custom_fields_171385_624438" value="624438"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171385_624438">Plastic</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171385][]"
                                        id="custom_fields_171385_624784" value="624784"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171385_624784">Organic Fabric</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171385][]"
                                        id="custom_fields_171385_669092" value="669092"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171385_669092">Mother of pearl</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171385][]"
                                        id="custom_fields_171385_669123" value="669123"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171385_669123">Malacca / Rattan</label>
                                </div>
                                <div class="checkbox-container">
                                    <input type="checkbox" name="custom_fields[171385][]"
                                        id="custom_fields_171385_669385" value="669385"
                                        class="custom_field_checkbox required">
                                    <label class="light" for="custom_fields_171385_669385">Synthetic fabric</label>
                                </div>
                            </div>
                        </div>

                        <label for="custom_fields_183830">Delivery time (days)*</label>
                        <input type="text" name="custom_fields[183830]" id="custom_fields_183830" value=""
                            class="required">

                    </div>


                    <label class="input" for="listing_image">Image</label>
                    <div class="info-text-container">
                        <div class="info-text-icon">
                            <i class="ss-info"></i>
                        </div>
                        <div class="info-text-content">
                            <p>For best results, use JPG, GIF or PNG images that are 660x440 pixels</p>
                        </div>
                    </div>

                    <div class="flash-notifications js-listing-image-loading hidden" style="display: none;">
                        <div class="flash-notice flash-notification">
                            <div class="flash-icon ss-loading"></div>
                            <div class="flash-text">
                                Image upload in progress...
                            </div>
                        </div>
                    </div>
                    <div class="flash-notifications js-listing-image-loading-done hidden" style="display: none;">
                        <div class="flash-notice flash-notification">
                            <div class="flash-icon ss-check"></div>
                            <div class="flash-text">
                                All images uploaded! Processing them will take a bit of time, but it's ok to save the
                                listing and move on.
                            </div>
                        </div>
                    </div>
                    <div class="listing-images ui-sortable" id="image-uploader-container">
                        <script class="template" id="new-image-tmpl" type="text/template">
<div class='fileinput-button upload-image-placeholder' id='fileupload'>
<div class='fileupload-text-container'>
<div class='fileupload-centered-text'>
<div class='fileupload-text'></div>
<div class='fileupload-small-text'></div>
<div class='fileupload-error-text'></div>
</div>
</div>
<input class='fileupload' multiple='multiple' type='file'>
<img class='fileupload-preview-image'>
<div class='fileupload-preview-remove-image'>
<i class="ss-delete icon-fix"></i>
</div>
<input class='listing-image-id' name='listing_images[][id]' type='hidden'>
</div>
</script>
                        <div class="fileinput-button upload-image-placeholder" id="fileupload">
                            <div class="fileupload-text-container" style="">
                                <div class="fileupload-centered-text">
                                    <div class="fileupload-text">Select file</div>
                                    <div class="fileupload-small-text"></div>
                                    <div class="fileupload-error-text"></div>
                                </div>
                            </div>
                            <input class="fileupload" multiple="multiple" type="file" style="display: inline-block;">
                            <img class="fileupload-preview-image" style="display: none;">
                            <div class="fileupload-preview-remove-image">
                                <i class="ss-delete icon-fix"></i>
                            </div>
                            <input class="listing-image-id" name="listing_images[][id]" type="hidden">
                        </div>
                        <div class="fileinput-button upload-image-placeholder" id="fileupload">
                            <div class="fileupload-text-container" style="">
                                <div class="fileupload-centered-text">
                                    <div class="fileupload-text">Select file</div>
                                    <div class="fileupload-small-text"></div>
                                    <div class="fileupload-error-text"></div>
                                </div>
                            </div>
                            <input class="fileupload" multiple="multiple" type="file" style="display: inline-block;">
                            <img class="fileupload-preview-image" style="display: none;">
                            <div class="fileupload-preview-remove-image">
                                <i class="ss-delete icon-fix"></i>
                            </div>
                            <input class="listing-image-id" name="listing_images[][id]" type="hidden">
                        </div>
                        <div class="fileinput-button upload-image-placeholder" id="fileupload">
                            <div class="fileupload-text-container" style="">
                                <div class="fileupload-centered-text">
                                    <div class="fileupload-text">+ Add more</div>
                                    <div class="fileupload-small-text"></div>
                                    <div class="fileupload-error-text"></div>
                                </div>
                            </div>
                            <input class="fileupload" multiple="multiple" type="file" style="display: inline-block;">
                            <img class="fileupload-preview-image" style="display: none;">
                            <div class="fileupload-preview-remove-image">
                                <i class="ss-delete icon-fix"></i>
                            </div>
                            <input class="listing-image-id" name="listing_images[][id]" type="hidden">
                        </div>
                    </div>

                    <input value="624262" type="hidden" name="listing[category_id]" id="listing_category_id">
                    <input value="275127" type="hidden" name="listing[listing_shape_id]" id="listing_listing_shape_id">
                    <button name="button" type="submit" class="send_button">Post listing</button>
                    <div class="info-text-container">
                        <div class="info-text-icon">
                            <i class="ss-info"></i>
                        </div>
                        <div class="info-text-content">
                            <p>We noticed you're an admin of MadKraftz. Your changes will be automatically applied and
                                will not need a review or approval.</p>
                        </div>
                    </div>


                    <input type="hidden" name="listing_ordered_images" id="listing_ordered_images">
                </form>
                <div class="lightbox" id="help_valid_until">
                    <div class="lightbox-content">
                        <a class="close lightbox-x" href="#" id="close_x">
                            <i class="ss-delete"></i>
                        </a>
                        <h2>Expiration date</h2>
                        <p></p>
                        <p></p>
                        <p>You can determine how long your listing is open. After the expiration date the listing will
                            close automatically. Closed listings are not visible in any normal listing views. You can
                            change the expiration date any time from the "edit listing" menu. You can also reopen the
                            listing after it has closed from the same menu, if it is still topical.</p>
                        <p></p>
                        <p></p>

                    </div>
                </div>

                <div class="lightbox" id="markdown-help-popup">
                    <div class="lightbox-content">
                        <a class="close lightbox-x" href="#" id="close_x">
                            <i class="ss-delete"></i>
                        </a>
                        <h2>Formatting help using Markdown</h2>
                        <p>Markdown is a simple way to format text that looks great on any device. It doesn’t do
                            anything fancy like change the font size, color, or type — just the essentials, using
                            keyboard symbols you already know.</p>
                        <table class="markdown-help-table">
                            <tbody>
                                <tr>
                                    <th>Formatting</th>
                                    <th>Result</th>
                                </tr>
                                <tr>
                                    <td># Your H1 text goes here<br>## Your H2 text goes here<br>### Your H3 text goes
                                        here
                                    </td>
                                    <td>
                                        <h1>Your H1 text goes here</h1>
                                        <br>
                                        <h2>Your H2 text goes here</h2>
                                        <br>
                                        <h3>Your H3 text goes here</h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td>*italic*</td>
                                    <td>
                                        <i>italic</i>
                                    </td>
                                </tr>
                                <tr>
                                    <td>**bold**</td>
                                    <td>
                                        <b>bold</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>_underline_</td>
                                    <td>
                                        <u>underline</u>
                                    </td>
                                </tr>
                                <tr>
                                    <td>~~strikethrough~~</td>
                                    <td>
                                        <!-- <strike>strikethrough</strike> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td>[Inline link](https://www.example.com)</td>
                                    <td>
                                        <a href="https://www.example.com" target="_blank">Inline link</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>- List<br>- List<br>- List
                                    </td>
                                    <td>
                                        <ul>
                                            <li>
                                                List
                                                <br>
                                                <br>
                                            </li>
                                            <li>
                                                List
                                                <br>
                                                <br>
                                            </li>
                                            <li>
                                                List
                                                <br>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        1. One
                                        <br>
                                        2. Two
                                        <br>
                                        3. Three
                                    </td>
                                    <td>
                                        <ol>
                                            <li>
                                                One
                                                <br>
                                                <br>
                                            </li>
                                            <li>
                                                Two
                                                <br>
                                                <br>
                                            </li>
                                            <li>
                                                Three
                                                <br>
                                                <br>
                                            </li>
                                        </ol>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                <script>
                    $('#markdown-popup-link').click(function () { $('#markdown-help-popup').lightbox_me({ centered: true, zIndex: 1000000 }); return false });
                </script>


            </div>
        </div>

    </div>
</article>