import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.css', './../information-styles.css']
})
export class HowItWorksComponent implements OnInit {

  usertype!: string;
  shopUrl = environment.shopUrl;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.queryParams.subscribe((params: any) => {
      this.usertype = this.route.snapshot.params['userType'];
      if (this.usertype === undefined || this.usertype === null) {
        this.usertype = "buyer";
      }
    });
  }

  isMenuOut!: boolean;

  ngOnInit(): void {
    this.isMenuOut = false;
  }

  toggleMenu() {
    this.isMenuOut = !this.isMenuOut;
  }

  changeUserType(userType: string) {
    this.usertype = userType;
    this.router.navigateByUrl('/how_it_works/:userType', { skipLocationChange: false })
      .then(() => this.router.navigate(['how_it_works', this.usertype]));
  }

}
