import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css', './../information-styles.css']
})
export class AboutComponent implements OnInit {

  isMenuOut!: boolean;
  constructor(private route: ActivatedRoute,
    private viewportScroller: ViewportScroller) {
  }

  ngOnInit(): void {
    this.isMenuOut = false;
    window.scroll({ top: 0 });
  }

  toggleMenu() {
    this.isMenuOut = !this.isMenuOut;
  }

  ngAfterViewInit() {
    // Scrolls to top of page after page view is
    // initialized if fragment (anchor) is null
    this.route.fragment.subscribe(fragmentData => {
      this.viewportScroller.scrollToAnchor(fragmentData!);
      if (fragmentData === undefined || fragmentData === null) {
        this.scrollTo('top');
      }
    });
  }

  scrollTo(id: string) {
    let element = document.getElementById(id);
    if (element !== null) {
      element.scrollIntoView();
      element = null;
    }
  }

}
