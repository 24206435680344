<section class="marketplace-lander">
    <div class="coverimage">
        <figure class="marketplace-cover-small fluidratio"></figure>
        <div class="coverimage-fade">
            <figure class="marketplace-cover-small-fade fluidratio"></figure>
        </div>
    </div>
    <div class="title-container">
        <div class="title-header-wrapper">
            <div class="marketplace-title-header">
                <h1 class="p-0 m-0">Information about MadKraftz</h1>
            </div>
        </div>
    </div>
</section>

<article class="page-content">
    <div class="wrapper">
        <app-left-menu></app-left-menu>
        <div class="left-navi-section about-section">
            <div
                style="font-weight: 300; padding: 30px 48px; border-radius: 5px 5px 0 0; background-color: #1d4436; color: whitesmoke;">
                <div data-mercury="full" id="about_page_content">
                    <div style="position: relative;">
                        <div>
                            <div class="about-quote-bg">
                            </div>
                            <div>
                                <h1><b>
                                        <font color="#ff7b08">HOW IT ALL STARTED</font>
                                    </b></h1>
                                <div class="quote">
                                    <p><span class="quote-mark">"</span>When I was younger, I thought my father was
                                        stingy, he just wouldn’t buy anything new unless the onld one was broken.
                                        The reality was that we had a modest income and he did his best to provide for
                                        us some of the things we were missing, by
                                        upcycling old items, when no one even knew there was a word for it...</p>
                                    <p>Growing up, we had an old freezer that broke and my father transformed it into a
                                        grill, it didn’t look pretty, but it
                                        worked. We had a small yellow car that my father bumped in the front, he then
                                        bought a red one, but he got bumped in the
                                        back after a couple of years.. Needless to say we then had a half red, half
                                        yellow car for a while, but it worked and we
                                        all loved it!
                                    </p>
                                    <p>In the back of the house, I gathered small things from his auto repair shop,
                                        including nearly finished spray paint and
                                        broken items he couldn’t reuse for clients. I would make my own miniature
                                        furniture and decorations, and that’s how I
                                        got hooked!<span class="quote-mark">"</span></p>
                                </div>
                                <h4 style="text-align: right; color: white;">Paula Ursu | Big Chief <font
                                        color="#ff7b08">@MADKRAFTZ</font>&nbsp;
                                    &nbsp; &nbsp;
                                    &nbsp;</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style="font-weight: 300; padding:30px 48px; border-radius: 0 0 5px 5px; background-color: #22503f; color: whitesmoke; margin-bottom: 50px;">
                <h1 id="about"><b>
                        <font color="#ff7b08">ABOUT US</font>
                    </b></h1>
                <p>Have you ever looked around your house and wondered what you could do with the things you've
                    collected? Have you ever been so frustrated by the lack of space in your home that you <font
                        color="#649fbe">almost threw away beautiful keepsakes</font>
                    because they didn't fit
                    anywhere? Well, haven’t we all?</p>
                <p>Are you ready to have your
                    <font color="#649fbe">rubbish&nbsp;turned into treasure</font>
                    ? We're the genies in a bottle you've been waiting for. (But way better than that, because we're
                    no longer in an old dirty bottle). At MadKraftz, we believe that
                    <font color="#649fbe">upcycling is a way of life</font>
                    ! We're here to have your wishes granted.
                </p>
                <p>We started
                    <font color="#649fbe">MadKraftz</font>: a curated online marketplace for <font color="#649fbe">
                        upcycled and refinished items - even antiques!</font>
                    By using reclaimed and recycled materials, we're dedicated to helping people turn their keepsakes
                    into something new and
                    beautiful.
                </p>

                <p>We offer an online platform where you can find the most beautiful
                    <font color="#649fbe">furniture, lights and art & decor, with a special category for under 200!
                    </font> Our curated selection features only the best items from our top of the range
                    sellers.&nbsp;<span style="font-size: 1em;">We made our marketplace with one goal in mind: helping
                        you get what you want with minimal stress. Our
                        <font color="#649fbe">free interior design advice</font>
                        will help you choose what best suits your space.
                    </span>
                </p>
                <h1 id="mission" class="h1-space">
                    <font color="#ff7b08">OUR MISSION</font>
                </h1>
                <p>If you're looking for
                    <font color="#649fbe">ECO-FRIENDLY</font> alternatives to mainstream products in your <font
                        color="#649fbe">home, your
                        B&amp;B or any other business</font>, we can help reflect your eco-conscious values! We've got
                    tons of ideas in order to help you
                    put together a space that feels great for you or for your guests, and leaves
                    less of an impact on our planet.
                </p>
                <p>We love what we do because we believe in helping everyone, from our clients who are looking to buy
                    something “new” or repurpose something old, to our sellers of upcycled and refinished items who may
                    have never had a chance to show off their work before they found us.
                    And through it all, we get to
                    <font color="#649fbe">help save the planet!</font>
                </p>
                <h1 id="upcycling" class="h1-space"><a name="Upcycling terms">
                        <font color="#ff7b08">A COUPLE OF NEW WORDS!</font>
                    </a></h1>
                <div>
                    <p>When you
                        <font color="#649fbe">recycle</font>
                        an item, it comes back as a
                        <font color="#649fbe">completely different thing</font>,
                        you can't tell how it was used before. With
                        <font color="#649fbe">upcycling</font>,
                        on the other hand,
                        you can see what the <font color="#649fbe">original item</font> used to be
                        and sometimes even how <font color="#649fbe">much better</font> it is now
                        <font color="#649fbe">
                            than before!</font>
                    </p>

                    <p>
                        Upcycling involves adding value (in the eye of the beholder or the upcycler) to something that
                        would otherwise have been
                        thrown away, recycled, lost in storage or abandoned.  Upcycling can involve repurposing and
                        reusing, refreshing,
                        revamping and reviving or creating and re-creating. And it's not just for wood! You can upcycle
                        just about anything,
                        from clothing to electronics to appliances! 
                    </p>

                    <p>You might have heard the term
                        <font color="#649fbe">"refinished"</font>
                        before, it means restoring something to working order again after it's been damaged or broken.
                        When we refinish an item, we're
                        making sure it <font color="#649fbe">looks good as new </font>while still being <font
                            color="#649fbe">good to&nbsp;the
                            environment!</font>
                    </p>
                    <p>Finally there's
                        <font color="#649fbe">"reclaimed,"</font>
                        which means
                        <font color="#649fbe">pieces</font> that had <font color="#649fbe">already</font> been <font color="#649fbe">used
                        </font> for something else
                        but had been taken apart and
                        put back together again into <font color="#649fbe">new items like furniture or any kind of art really.</font>
                    </p>
                    <p>Another term used is
                        <font color="#649fbe">"junk art",</font>
                        which is when artists use
                        <font color="#649fbe">reclaimed materials</font>
                        like old bottles or cans to create
                        <font color="#649fbe"> beautiful art pieces like sculptures, jewelry boxes and other decorations
                        </font>. Junk art is a great way to reuse something that would otherwise end up in the rubbish!
                    </p>
                </div>
                <p></p>
            </div>
        </div>
    </div>
</article>