<div *ngIf="!production" class="px-5 py-3 text-center" style="background-color: rgb(255, 123, 8); color: white;">THIS IS A
    {{name}} ENVIRONMENT</div>
<div id="topbar-placeholder" class="topbar-placeholder">
    <div data-reactroot="" class="Topbar Topbar__topbar__7GUWt">
        <div [ngClass]="{'MenuMobile__canvasOpen__3CorC': isMenuOut, '': !isMenuOut}" (click)="toggleMenu()"
            class="Topbar__topbarMobileMenu__3z-JE MenuMobile MenuMobile__menuMobile___Ivzx Topbar__topbarMobileMenu__3z-JE"
            tabindex="0">
            <div class="MenuMobile_overlay MenuMobile__overlay__2sZqq" style="background-color: rgb(255, 123, 8);">
            </div>
            <div class="MenuLabelMobile MenuMobile__menuLabelMobile__3uXwZ "><span
                    class="MenuMobile__menuLabelMobileIcon__14XBz" title="Menu"><svg width="18" height="12"
                        viewBox="18 19 18 12" xmlns="http://www.w3.org/2000/svg">
                        <g fill="#34495E" fill-rule="evenodd" transform="translate(18 19)">
                            <rect width="18" height="2" rx="1"></rect>
                            <rect y="5" width="18" height="2" rx="1"></rect>
                            <rect y="10" width="18" height="2" rx="1"></rect>
                        </g>
                    </svg></span></div>
            <div class="OffScreenMenu MenuMobile__offScreenMenu__2fKaS">
                <div class="OffScreenMenu_scrollpane MenuMobile__scrollPane__2VvXd">
                    <div class="OffScreenMenu_header MenuMobile__offScreenHeader__KnANh">
                        <div class="LoginLinks LoginLinks__links__z-Tnd"><a
                                class="Link__link__3pNRT LoginLinks__link__1GboG" href="{{shopUrl}}/en/signup"
                                style="color: rgb(255, 123, 8);">Sign
                                up</a><a class="Link__link__3pNRT LoginLinks__link__1GboG" href="{{shopUrl}}/en/login"
                                style="color: rgb(255, 123, 8);">Log
                                in</a></div>
                    </div>
                    <div class="OffScreenMenu_header MenuMobile__offScreenHeader__KnANh"><a
                            class="MenuMobile__offScreenHeaderNewListingButtonMobile__2OZrJ AddNewListingButton AddNewListingButton__button__2H8yh"
                            href="{{shopUrl}}/en/listings/new" title="Post a new listing"><span
                                class="AddNewListingButton__backgroundContainer__4Nn3Z AddNewListingButton_background"
                                style="background-color: rgb(255, 123, 8);"></span><span
                                class="AddNewListingButton__mobile__2JhHF AddNewListingButton_mobile"
                                style="color: rgb(255, 123, 8);">+ Post a new listing</span><span
                                class="AddNewListingButton__desktop__17Luf AddNewListingButton_desktop">+ Post a new
                                listing</span></a></div>
                    <div class="OffScreenMenu_main MenuMobile__offScreenMain__1ruzb">
                        <div class="MenuSection MenuMobile__menuSection__gD-As">
                            <div class="MenuSection_title MenuMobile__menuSectionTitle__2HlQg">Menu</div>
                            <div class="MenuItem MenuItem__menuitem__3_-l_  MenuMobile__menuSectionMenuItem__2yMLC"><a
                                    class="MenuItem_link MenuItem__menuitemLink__2Eedg MenuMobile__menuSectionMenuItemLink__GfyW6"
                                    href="/" routerLink="/" style="color: rgb(255, 123, 8);">Home</a></div>
                            <div class="MenuItem MenuItem__menuitem__3_-l_  MenuMobile__menuSectionMenuItem__2yMLC"><a
                                    class="MenuItem_link MenuItem__menuitemLink__2Eedg MenuMobile__menuSectionMenuItemLink__GfyW6"
                                    href="/about" [routerLink]="['/about']" routerLinkActive="active"
                                    style="color: rgb(255, 123, 8);">About</a>
                            </div>
                            <div class="MenuItem MenuItem__menuitem__3_-l_  MenuMobile__menuSectionMenuItem__2yMLC"><a
                                    class="MenuItem_link MenuItem__menuitemLink__2Eedg MenuMobile__menuSectionMenuItemLink__GfyW6"
                                    href="{{shopUrl}}/en/user_feedbacks/new" style="color: rgb(255, 123, 8);">Contact
                                    us</a></div>
                            <div class="MenuItem MenuItem__menuitem__3_-l_  MenuMobile__menuSectionMenuItem__2yMLC"><a
                                    class="MenuItem_link MenuItem__menuitemLink__2Eedg MenuMobile__menuSectionMenuItemLink__GfyW6"
                                    href="/how_it_works/creator" (click)="gotoHowToSection('creator')" 
                                    [routerLink]="['/how_it_works/', 'creator',{ skipLocationChange: true }]"
                                    routerLinkActive="active" style="color: rgb(255, 123, 8);">Become a Seller</a></div>
                            <div class="MenuItem MenuItem__menuitem__3_-l_  MenuMobile__menuSectionMenuItem__2yMLC"><a
                                    class="MenuItem_link MenuItem__menuitemLink__2Eedg MenuMobile__menuSectionMenuItemLink__GfyW6"
                                    href="/how_it_works/designer" (click)="gotoHowToSection('designer')" 
                                    [routerLink]="['/how_it_works/', 'designer',{ skipLocationChange: true }]"
                                    routerLinkActive="active" style="color: rgb(255, 123, 8);">Design Interiors</a>
                            </div>
                        </div><!-- react-empty: 30 -->
                    </div>
                    <div class="OffScreenMenu_footer MenuMobile__offScreenFooter__3sjuV"></div>
                </div>
            </div>
        </div><a class="Logo Topbar__topbarLogo__2_AjG Logo__logo__3sGgU" routerLink="/" href="/"
            style="color: rgb(255, 123, 8);"><img src="/assets/images/madkraftz_logo.png" alt="MadKraftz"
                class="Logo__logoImage__3oOkB" srcset="/assets/images/madkraftz_logo.png 2x"></a>
        <div class="Topbar__topbarMediumSpacer__cbOwg"></div>
        <div class="Topbar__topbarMobileSearchPlaceholder__117MG"></div>
        <div class="Topbar__topbarMenuSpacer__3hqBi">
            <div class="MenuPriority MenuPriority__menuPriority__3y3Pu MenuPriority__isMeasured__12Xiw">
                <div class="MenuPriority__priorityLinks__XgHdH" style="width: 383px;"><a data-pid="About 0"
                        class="MenuPriority__priorityLink__moBbL" href="/about" [routerLink]="['/about']"
                        routerLinkActive="active">About</a><a data-pid="Become a Seller 0"
                        class="MenuPriority__priorityLink__moBbL" href="/how_it_works/creator"
                        [routerLink]="['/how_it_works/', 'creator',{ skipLocationChange: false }]" (click)="gotoHowToSection('creator')"
                        routerLinkActive="active">Become a Seller</a><a data-pid="Design Interiors 1"
                        class="MenuPriority__priorityLink__moBbL" href="/how_it_works/designer"
                        [routerLink]="['/how_it_works/', 'designer',{ skipLocationChange: false }]" (click)="gotoHowToSection('designer')"
                        routerLinkActive="active">Design Interiors</a><a data-pid="Contact us 2"
                        class="MenuPriority__priorityLink__moBbL" href="{{shopUrl}}/en/user_feedbacks/new">Contact
                        us</a></div>
            </div>
        </div>
        <!-- AvatarDropdown__openDropdown__1PYh9 -->
        <div *ngIf="currentUser!==null" id="userDropdown" tabindex="0"
            class="AvatarDropdown Topbar__topbarAvatarDropdown__3FkXQ AvatarDropdown__avatarDropdown__1Vjt6"
            data-reactid="80" (mouseenter)="showDropDown()">
            <div class="AvatarDropdown__avatarWithNotifications__1TdZT" data-reactid="81">
                <div class="Avatar Avatar__textAvatar__4oTir" style="height:100%;width:100%;"
                    title="{{currentUser.firstName}} {{currentUser.lastName}}" data-reactid="82">
                    {{currentUser.firstName.charAt(0)}}{{currentUser.lastName.charAt(0)}}</div>
            </div>
            <div class="AvatarDropdown__avatarProfileDropdown__2gYjH" data-reactid="83">
                <div class="ProfileDropdown__rootArrowTop__3Vvtx" data-reactid="84"></div>
                <div class="ProfileDropdown__rootArrowBelow__3YUUJ" data-reactid="85"></div>
                <div (mouseleave)="hideDropDown()" class="ProfileDropdown__box__T_uM4" data-reactid="86">
                    <div class="ProfileDropdown__profileActions__34o4h" data-reactid="87"><a href="/en/paulau1/inbox"
                            class="ProfileDropdown__profileAction__1H9nm" data-reactid="88">
                            <div class="ProfileDropdown__profileActionIconWrapper__1v7N_" data-reactid="89">
                                <div data-reactid="90"><svg width="32" height="32" viewBox="0 0 32 32"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g xmlns="http://www.w3.org/2000/svg" id="svg_1" stroke="#333"
                                            stroke-linejoin="round" stroke-linecap="round" fill-rule="evenodd"
                                            fill="none">
                                            <path
                                                d="m25.854154,11.733l5.174,3.594l0,14.122c0,1.113 -0.877,2.018 -1.957,2.018l-26.096,0c-1.081,0 -1.958,-0.905 -1.958,-2.018l0,-14.122l5.175,-3.594">
                                            </path>
                                            <path class="notification-background"
                                                d="m6.337491,0.692001l19.248457,0l0.006201,17.908751l-4.009665,3.150067l-10.912901,0.010181l-4.40443,-3.035019"
                                                fill="#FF4E36"></path>
                                            <path
                                                d="m4.640154,27.733l6.726,-5.866l9.313,0l6.727,5.866m3.622,-12.406l-9.314,6.54m-20.697,-6.54l9.314,6.54m15.523,-2.984l0,-18.35l-19.662,0l0,18.35">
                                            </path>
                                        </g>
                                    </svg></div>
                            </div>
                            <div class="ProfileDropdown__profileActionLabel__2lW8j" data-reactid="91">Inbox</div>
                        </a><a href="/en/paulau1/settings/listings" class="ProfileDropdown__profileAction__1H9nm"
                            data-reactid="92">
                            <div class="ProfileDropdown__profileActionIconWrapper__1v7N_" data-reactid="93">
                                <div data-reactid="94"><svg width="29" height="32" xmlns="http://www.w3.org/2000/svg">
                                        <g stroke="#333" fill="none" fill-rule="evenodd" stroke-linecap="round"
                                            stroke-linejoin="round">
                                            <path d="M2.182 31.273H24V.727H2.182z"></path>
                                            <path
                                                d="M9.455 12.364h8.727V6.545H9.455zM.727 5.09h2.91M.727 9.455h2.91M.727 13.818h2.91M.727 18.182h2.91M.727 22.545h2.91M.727 26.91h2.91M24 9.455h4.364V3.636H24zM24 15.273h4.364V9.455H24zM24 21.09h4.364v-5.817H24zM24 26.91h4.364v-5.82H24z">
                                            </path>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div class="ProfileDropdown__profileActionLabel__2lW8j" data-reactid="95">My listings</div>
                        </a><a href="/en/paulau1" class="ProfileDropdown__profileAction__1H9nm" data-reactid="96">
                            <div class="ProfileDropdown__profileActionIconWrapper__1v7N_" data-reactid="97">
                                <div data-reactid="98"><svg width="33" height="32" viewBox="5 0 33 32"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g fill="none" fill-rule="evenodd" stroke-linejoin="round" stroke="#333">
                                            <path
                                                d="M18.354 20.035s-2.023-.672-2.023-4.035c-1.113 0-1.113-2.69 0-2.69 0-.45-2.022-5.38 1.349-4.707.674-2.69 8.09-2.69 8.764 0 .467 1.866-.674 4.377-.674 4.708 1.113 0 1.113 2.689 0 2.689 0 3.363-2.023 4.035-2.023 4.035v3.362c3.34 1.25 6.647 2.283 8.34 3.463A15.39 15.39 0 0 0 36.555 16C36.555 7.457 29.613.533 21.05.533S5.545 7.457 5.545 16a15.4 15.4 0 0 0 4.484 10.876c1.793-1.237 5.328-2.35 8.325-3.479v-3.362z">
                                            </path>
                                            <path
                                                d="M10.028 26.876a15.476 15.476 0 0 0 11.023 4.591c4.32 0 8.224-1.764 11.036-4.607">
                                            </path>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div class="ProfileDropdown__profileActionLabel__2lW8j" data-reactid="99">Profile</div>
                        </a><a href="/en/paulau1/settings" class="ProfileDropdown__profileAction__1H9nm"
                            data-reactid="100">
                            <div class="ProfileDropdown__profileActionIconWrapper__1v7N_" data-reactid="101">
                                <div data-reactid="102"><svg width="32" height="32" viewBox="11 0 32 32"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g fill="none" fill-rule="evenodd" stroke-linecap="round"
                                            stroke-linejoin="round" stroke="#333">
                                            <path
                                                d="M11.533 31.467h30.934V.533H11.533zm16-12.106v-9.227m6.4 9.227v-2.827m-13.866-4.267v-7.7m0 14.794v-4.96m13.866.064V4.568M27.533 8V4.567">
                                            </path>
                                            <path
                                                d="M31.8 16.533h4.267V14.4H31.8zm-6.4-6.4h4.267V8H25.4zM17.933 14.4H22.2v-2.133h-4.267zM31.8 25.067a2.133 2.133 0 1 1 4.267 0 2.133 2.133 0 0 1-4.267 0zm-6.4 0a2.133 2.133 0 1 1 4.267 0 2.133 2.133 0 0 1-4.267 0zm-7.467 0a2.133 2.133 0 1 1 4.267 0 2.133 2.133 0 0 1-4.267 0z">
                                            </path>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div class="ProfileDropdown__profileActionLabel__2lW8j" data-reactid="103">Settings</div>
                        </a></div>
                    <div class="ProfileDropdown__logoutArea__38Dcu" data-reactid="104"><a
                            class="ProfileDropdown__adminLink__342Ug" style="color:#ff7b08;" href="/en/admin"
                            data-reactid="105">Admin panel</a>
                        <div class="ProfileDropdown__logoutLink__15uxw" data-reactid="106" (click)="logout()">Log out
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="currentUser===null" class="LoginLinks LoginLinks__links__z-Tnd Topbar__topbarLinks__CCME8"><a
                class="Link__link__3pNRT LoginLinks__link__1GboG" href="{{shopUrl}}/en/signup"
                style="color: rgb(255, 123, 8);">Sign up</a><a class="Link__link__3pNRT LoginLinks__link__1GboG"
                href="{{shopUrl}}/en/login" style="color: rgb(255, 123, 8);">Log in</a>
        </div>
        <a class="Topbar__topbarListingButton__2SNl8 AddNewListingButton AddNewListingButton__button__2H8yh AddNewListingButton__responsiveLayout__1JnL9"
            href="{{shopUrl}}/en/listings/new" title="Post a new listing"><span
                class="AddNewListingButton__backgroundContainer__4Nn3Z AddNewListingButton_background"
                style="background-color: rgb(255, 123, 8);"></span><span
                class="AddNewListingButton__mobile__2JhHF AddNewListingButton_mobile" style="color: rgb(255, 123, 8);">+
                Post a new listing</span><span class="AddNewListingButton__desktop__17Luf AddNewListingButton_desktop">+
                Post a new listing</span>
        </a>
    </div>
</div>