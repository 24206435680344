import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import jwt_decode from "jwt-decode";
import { Role, User } from '../models';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')!));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is buyer
   */
  get isBuyer() {
    return this.currentUser && this.currentUserSubject.value.roles && 
      this.currentUserSubject.value.roles.includes(Role.Buyer);
  }

  /**
   *  Confirms if user is creator
   */
  get isCreator() {
    return this.currentUser && this.currentUserSubject.value.roles && 
      this.currentUserSubject.value.roles.includes(Role.Creator);
  }

  /**
   *  Confirms if user is creator
   */
  get isDesigner() {
    return this.currentUser && this.currentUserSubject.value.roles &&
      this.currentUserSubject.value.roles.includes(Role.Designer);
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/authenticate/login`, { email, password })
      .pipe(
        map(userResponse => {
          let user:User = new User();
          user.token = userResponse.token;
          userResponse = jwt_decode(userResponse.token);
          user.userId = userResponse.userId;
          user.roles = [];
          Array.from(userResponse.roles).forEach((role:any) => {
            user.roles.push((role.toString().split("_")[1]).toLowerCase());
          });
          user.status = userResponse.status;
          user.email = userResponse.email;
          user.firstName = userResponse.firstName;
          user.lastName = userResponse.lastName;
          user.displayName = userResponse.displayName;
          user.avatar = `${userResponse.userId}.jpg`;

          // login successful if there's a jwt token in the response
          if (user) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));

            // Display welcome toast!
            // setTimeout(() => {
            //   this._toastrService.success(
            //     'You have successfully logged in as an ' +
            //     user.roles +
            //     ' user to MClub. Now you can start to explore. Enjoy! 🎉',
            //     '👋 Welcome, ' + user.name + '!',
            //     { toastClass: 'toast ngx-toastr', closeButton: true }
            //   );
            // }, 2500);

            // notify
            this.currentUserSubject.next(user);
          }
          window.location.reload();
          return user;
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    window.location.reload();
    // notify
    // this.currentUserSubject.next();
  }
}
