import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './navigation/page-not-found/page-not-found.component';
import { LoginComponent } from './pages/authentication/login/login.component';
import { RegisterComponent } from './pages/authentication/register/register.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/information/about/about.component';
import { HowItWorksComponent } from './pages/information/how-it-works/how-it-works.component';
import { PrivacyComponent } from './pages/information/privacy/privacy.component';
import { TermsComponent } from './pages/information/terms/terms.component';
import { NewListingComponent } from './pages/new-listing/new-listing.component';
import { AdminHomeComponent } from './pages/user/admin/admin-home/admin-home.component';
import { ListingComponent } from './pages/user/listing/listing.component';
import { NotificationsComponent } from './pages/user/notifications/notifications.component';
import { ProfileComponent } from './pages/user/profile/profile.component';
import { SettingComponent } from './pages/user/setting/setting.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'how_it_works/:userType', component: HowItWorksComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'en/login', component: LoginComponent },
  { path: 'en/signup', component: RegisterComponent },
  { path: 'en/listings/new', component: NewListingComponent },

  { path: 'en/admin', component: AdminHomeComponent },
  { path: 'en/paulau1/inbox', component: NotificationsComponent },
  { path: 'en/paulau1/settings/listings', component: ListingComponent },
  { path: 'en/paulau1', component: ProfileComponent },
  { path: 'en/paulau1/settings', component: SettingComponent },

  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms', component: TermsComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', pathMatch: 'full', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, 
    { 
      scrollPositionRestoration: 'enabled', 
      onSameUrlNavigation: 'reload', 
      anchorScrolling: 'enabled' 
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
