import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit {
// Public
public coreConfig: any;
public passwordTextType!: boolean;
public confPasswordTextType!: boolean;
public resetPasswordForm!: FormGroup;
public submitted = false;

// Private
private _unsubscribeAll: Subject<any>;

/**
 * Constructor
 *
 * @param {CoreConfigService} _coreConfigService
 * @param {FormBuilder} _formBuilder
 */
constructor(private _formBuilder: FormBuilder) {
  this._unsubscribeAll = new Subject();
}

// convenience getter for easy access to form fields
get f() {
  return this.resetPasswordForm.controls;
}

/**
 * Toggle password
 */
togglePasswordTextType() {
  this.passwordTextType = !this.passwordTextType;
}

/**
 * Toggle confirm password
 */
toggleConfPasswordTextType() {
  this.confPasswordTextType = !this.confPasswordTextType;
}

/**
 * On Submit
 */
onSubmit() {
  this.submitted = true;

  // stop here if form is invalid
  if (this.resetPasswordForm.invalid) {
    return;
  }
}

// Lifecycle Hooks
// -----------------------------------------------------------------------------------------------------

/**
 * On init
 */
ngOnInit(): void {
  this.resetPasswordForm = this._formBuilder.group({
    newPassword: ['', [Validators.required]],
    confirmPassword: ['', [Validators.required]]
  });
}

/**
 * On destroy
 */
ngOnDestroy(): void {
  // Unsubscribe from all subscriptions
  this._unsubscribeAll.complete();
}
}
