import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './navigation/page-not-found/page-not-found.component';
import { HeaderComponent } from './navigation/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
import { FooterComponent } from './navigation/footer/footer.component';
import { BannerComponent } from './pages/home/banner/banner.component';
import { HelpComponent } from './pages/home/help/help.component';
import { MissionComponent } from './pages/home/mission/mission.component';
import { CuratedComponent } from './pages/home/curated/curated.component';
import { PresentationComponent } from './pages/home/presentation/presentation.component';
import { DifferenceComponent } from './pages/home/difference/difference.component';
import { DecorComponent } from './pages/home/decor/decor.component';
import { AuthenticationModule } from './pages/authentication/authentication.module';
import { NewListingComponent } from './pages/new-listing/new-listing.component';
import { InformationModule } from './pages/information/information.module';
import { FeaturedComponent } from './pages/home/featured/featured.component';
import { PlyrModule } from 'ngx-plyr';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ContactComponent,
    BannerComponent,
    HelpComponent,
    MissionComponent,
    CuratedComponent,
    PresentationComponent,
    DifferenceComponent,
    DecorComponent,
    NewListingComponent,
    FeaturedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    InformationModule,
    AuthenticationModule,
    PlyrModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
