import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-curated',
  templateUrl: './curated.component.html',
  styleUrls: ['./curated.component.css', './../home.component.css']
})
export class CuratedComponent implements OnInit {

  shopUrl = environment.shopUrl;
  constructor() { }

  ngOnInit(): void {
  }

}
