<div class="light-bg">
    <div class="container decor">
        <div class="row m-0 py-5 flex-md-row-reverse">
            <div class="col-md-6 col-sm-12 col-12">
                <div class="row">
                    <h1 class="col-12 title d-flex justify-content-end text-end">WE PUT THE ECO<br />
                        IN DECOR!</h1>
                    <div class="d-flex justify-content-end">
                        <div class="align-self-end">
                            <a class="landing-button--ghost landing-button" href="{{shopUrl}}/en/signup">SIGN UP!</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12 col-12 d-flex justify-content-start px-3">
                <p class="landing_text">Are you ready to have your garbage turned into treasure? We're the genies in a bottle you've been waiting for. But we're
                way better than that, because we're no longer in an old dirty bottle. At MadKraftz, we believe that upcycling is a way
                of life! We're here to make your creative wishes come true.<br /><br />
                    We started MadKraftz: a curated online marketplace for upcycled and refinished items — even antiques! By using also
                    reclaimed and recycled materials, we're dedicated to helping people turn their keepsakes into something new and
                    beautiful.<br /><br />
                    If you want to buy or sell beautifuly crafted handmade goods, sign up now!</p>
            </div>
        </div>
    </div>
</div>