<section id="services-wrap" class="services-wrap container">
        <div class="row">
            <div class="col-md-12 section-header mb-1">
                <div class="sec-title3 text-center m-1">
                    <h1 class="categories__title">Our Featured Products</h1>
                </div>
            </div>
            <div class="service-wrap col-md-6 col-lg-4 col-sm-12">
                <div class="card o-hidden mb-4">
                    <a
                        href="{{shopUrl}}/en/listings/1787300-vintage-carved-solid-oak-sideboard-slash-buffet-lush-green">
                        <div class="card-header p-0 text-center"><img
                                src="https://user-assets.sharetribe.com/images/listing_images/images/4905506/big/sideboard_lush_green.jpg?1652868738"
                                alt="Vintage Carved Solid Oak Sideboard/Buffet Lush Green" class="img-responsive"></div>
                        <div class="card-body">
                            <h3 class="categories__category-title">Vintage Carved Solid Oak Sideboard/Buffet Lush Green
                            </h3>
                        </div>
                    </a>
                </div>
            </div>
            <div class="service-wrap col-md-6 col-lg-4 col-sm-12">
                <div class="card o-hidden mb-4">
                    <a href="{{shopUrl}}/en/listings/1787117-clock-grandfather-clock-cabinet-gin-cabinet">
                        <div class="card-header p-0 text-center"><img
                                src="https://user-assets.sharetribe.com/images/listing_images/images/4904713/big/il_1588xn_3774917639_bfjy.jpg?1652832527"
                                alt="Clock - Grandfather Clock - Cabinet - Gin Cabinet" class="img-responsive"></div>
                        <div class="card-body">
                            <h3 class="categories__category-title">Clock - Grandfather Clock - Cabinet - Gin Cabinet
                            </h3>
                        </div>
                    </a>
                </div>
            </div>
            <div class="service-wrap col-md-6 col-lg-4 col-sm-12">
                <div class="card o-hidden mb-4">
                    <a href="{{shopUrl}}/en/listings/1787085-antique-1903-kodak-camera-upcycled-tripod-lamp">
                        <div class="card-header p-0 text-center"><img
                                src="https://user-assets.sharetribe.com/images/listing_images/images/4904591/big/camera.jpg?1652828135"
                                alt="Antique 1903 Kodak Camera Upcycled Tripod Lamp" class="img-responsive"></div>
                        <div class="card-body">
                            <h3 class="categories__category-title">Antique 1903 Kodak Camera Upcycled Tripod Lamp</h3>
                        </div>
                    </a>
                </div>
            </div>
            <div class="service-wrap col-md-6 col-lg-4 col-sm-12">
                <div class="card o-hidden mb-4">
                    <a href="{{shopUrl}}/en/listings/1786983-driftwood-kitchen-over-table-dimmable-lighting">
                        <div class="card-header p-0 text-center"><img
                                src="https://user-assets.sharetribe.com/images/listing_images/images/4904250/big/il_1588xn_3328583870_icgi.jpg?1652811007"
                                alt="Driftwood Kitchen Over Table Dimmable Lighting" class="img-responsive"></div>
                        <div class="card-body">
                            <h3 class="categories__category-title">Driftwood Kitchen Over Table Dimmable Lighting</h3>
                        </div>
                    </a>
                </div>
            </div>
            <div class="service-wrap col-md-6 col-lg-4 col-sm-12">
                <div class="card o-hidden mb-4">
                    <a href="{{shopUrl}}/en/listings/1786965-large-vintage-green-italian-demijohn">
                        <div class="card-header p-0 text-center"><img
                                src="https://user-assets.sharetribe.com/images/listing_images/images/4904162/big/green_demijohn.jpg?1652809475"
                                alt="Large Vintage Green Italian Demijohn" class="img-responsive"></div>
                        <div class="card-body">
                            <h3 class="categories__category-title">Large Vintage Green Italian Demijohn</h3>
                        </div>
                    </a>
                </div>
            </div>
            <div class="service-wrap col-md-6 col-lg-4 col-sm-12">
                <div class="card o-hidden mb-4">
                    <a
                        href="{{shopUrl}}/en/listings/1790646-upcycled-rare-1940s-perihel-twin-headed-articulated-medical-lamp">
                        <div class="card-header p-0 text-center"><img
                                src="https://user-assets.sharetribe.com/images/listing_images/images/4917752/big/medical.jpg?1653388149"
                                alt="Upcycled Rare 1940s Perihel Twin Headed Articulated Medical
                        Lamp" class="img-responsive"></div>
                        <div class="card-body">
                            <h3 class="categories__category-title">Upcycled Rare 1940s Perihel Twin Headed Articulated
                                Medical
                                Lamp</h3>
                        </div>
                    </a>
                </div>
            </div>
        </div>
</section>