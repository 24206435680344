import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  name = environment.name;
  production = environment.production;

  currentUser!:any;
  // MenuMobile__canvasOpen__3CorC

  isMenuOut:boolean = false;
  baseUrl = environment.baseUrl;
  shopUrl = environment.shopUrl;
  
  constructor(private _authenticationService: AuthenticationService, private router:Router 
    ) {
    // this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    // this.isAdmin = this._authenticationService.isAdmin;
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    console.log("Current User: " + JSON.stringify(this.currentUser));
  }

  toggleMenu() {
    this.isMenuOut = !this.isMenuOut;
  }

  showDropDown() {
    let dropdown = document.getElementById("userDropdown");
    dropdown!.classList.add("AvatarDropdown__openDropdown__1PYh9");
  }

  hideDropDown() {
    let dropdown = document.getElementById("userDropdown");
    dropdown!.classList.remove("AvatarDropdown__openDropdown__1PYh9");
  }

  logout() {
    this._authenticationService.logout();
  }

  gotoHowToSection(userType:string) {
    console.log(userType)
    this.router.navigateByUrl('/how_it_works', { skipLocationChange: false })
      .then(() => this.router.navigate(['how_it_works', userType]));
  }

}
