<footer class="footer__container--dark">
    <div class="footer__content">
        <div class="footer__links-container">
            <ul class="footer__link-list">
                <li class="footer__link-list-item">
                    <a class="footer__link" routerLink="/about" href="/about">About Us</a>
                </li>
                <li class="footer__link-list-item">
                    <a class="footer__link" href="/how_it_works/buyer">How it works</a>
                </li>
                <li class="footer__link-list-item">
                    <a class="footer__link" href="/how_it_works/creator">Become a
                        Seller</a>
                </li>
                <li class="footer__link-list-item">
                    <a class="footer__link" href="/how_it_works/designer">Interior
                        Designers</a>
                </li>
                <li class="footer__link-list-item">
                    <a class="footer__link" target="_blank" rel="noreferrer"
                        href="https://join.slack.com/t/madkraftz/shared_invite/zt-1hrprx2mc-sfDUOXXQectd~mevsKOz1A">Our
                        Slack Community</a>
                </li>
                <li class="footer__link-list-item">
                    <a class="footer__link" target="_blank" rel="noreferrer" href="https://www.tiktok.com/@junkartcreativeupcyclers">Tik Tok</a>
                </li>
            </ul>
            <div class="footer__social-media">
                <a class="footer__social-media-link" href="https://www.facebook.com/JunkArtMarket" rel="noreferrer"
                    target="_blank">
                    <svg class="footer__social-media-icon footer__facebook-icon" version="1.1" viewBox="0 0 12 22"
                        xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.78896556,22 L7.78896556,11.9648254 L11.3270218,11.9648254 L11.8567155,8.05386965 L7.78896556,8.05386965 L7.78896556,5.55689722 C7.78896556,4.42458426 8.11918279,3.65297638 9.8247272,3.65297638 L12,3.65200544 L12,0.15408855 C11.623687,0.106512375 10.3324948,0 8.83030221,0 C5.69405446,0 3.54703063,1.82255587 3.54703063,5.16968541 L3.54703063,8.05386965 L0,8.05386965 L0,11.9648254 L3.54703063,11.9648254 L3.54703063,22 L7.78896556,22 Z"
                            fill-rule="evenodd" id="Facebook" stroke="none"></path>
                    </svg>

                </a>
                <a class="footer__social-media-link" href="https://twitter.com/JunkArtSocial" rel="noreferrer"
                    target="_blank">
                    <svg class="footer__social-media-icon footer__twitter-icon" version="1.1" viewBox="48 2 21 18"
                        xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M68.4207941,2.33276729 C67.5875898,2.8529685 66.6669159,3.23129666 65.6828825,3.43372529 C64.8990201,2.55134408 63.778736,2 62.5384615,2 C60.1610819,2 58.2317037,4.03466726 58.2317037,6.54397488 C58.2317037,6.90038768 58.2687101,7.24584281 58.3427229,7.57861011 C54.7620751,7.38886931 51.5879369,5.58200634 49.4617521,2.829323 C49.0905668,3.50293166 48.8786212,4.28438691 48.8786212,5.11659351 C48.8786212,6.69219186 49.6389341,8.08323988 50.7956639,8.89814488 C50.0902998,8.87622953 49.4247457,8.66976387 48.8427362,8.33180609 L48.8427362,8.38717119 C48.8427362,10.5896639 50.3280378,12.427093 52.3005901,12.8429079 C51.9394975,12.9496011 51.5582196,13.003236 51.165167,13.003236 C50.8876191,13.003236 50.6167997,12.9755535 50.3543908,12.9224953 C50.9027581,14.7270514 52.4929111,16.0413957 54.3785545,16.0765756 C52.9044669,17.2957611 51.0457373,18.022428 49.0277681,18.022428 C48.6801324,18.022428 48.3364216,18.0016661 48,17.9601423 C49.9063893,19.2473807 52.1721891,20 54.6045177,20 C62.530051,20 66.8626011,13.0770562 66.8626011,7.07167345 C66.8626011,6.87385858 66.8592369,6.67662042 66.8519478,6.48284259 C67.6935626,5.84210695 68.424719,5.04219666 69,4.13097946 C68.228473,4.49200602 67.3975115,4.7365352 66.5261795,4.84611195 C67.4160147,4.28438691 68.0989507,3.39393163 68.4207941,2.33276729"
                            fill-rule="evenodd" id="Twitter" stroke="none"></path>
                    </svg>

                </a>
                <a class="footer__social-media-link" href="https://www.instagram.com/junkartmarket/" rel="noreferrer"
                    target="_blank">
                    <svg class="footer__social-media-icon footer__instagram-icon" viewBox="397 291 22 21"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M411.714 301.43c0 1.887-1.54 3.427-3.428 3.427-1.89 0-3.43-1.54-3.43-3.428 0-1.89 1.54-3.43 3.43-3.43 1.888 0 3.428 1.54 3.428 3.43zm1.85 0c0-2.92-2.36-5.278-5.278-5.278-2.92 0-5.277 2.357-5.277 5.277 0 2.918 2.356 5.275 5.276 5.275s5.277-2.357 5.277-5.276zm1.445-5.493c0-.683-.55-1.232-1.233-1.232s-1.232.55-1.232 1.232c0 .684.55 1.233 1.232 1.233.683 0 1.232-.55 1.232-1.233zm-6.724-2.946c1.5 0 4.714-.12 6.067.416.468.188.817.415 1.178.777.363.362.59.71.778 1.18.536 1.35.415 4.566.415 6.066s.12 4.713-.415 6.066c-.187.468-.415.816-.777 1.178-.36.362-.71.59-1.177.777-1.353.537-4.567.416-6.067.416s-4.715.12-6.067-.415c-.47-.187-.818-.414-1.18-.776-.36-.362-.59-.71-.777-1.178-.535-1.353-.415-4.567-.415-6.067s-.12-4.716.415-6.068c.188-.47.416-.817.777-1.18.362-.36.71-.588 1.18-.776 1.35-.535 4.566-.415 6.066-.415zm10.285 8.44c0-1.42.015-2.827-.066-4.247-.08-1.647-.455-3.107-1.66-4.312-1.206-1.205-2.665-1.58-4.313-1.66-1.418-.08-2.825-.067-4.244-.067-1.42 0-2.826-.014-4.246.067-1.647.08-3.107.455-4.312 1.66-1.206 1.206-1.58 2.666-1.66 4.313-.08 1.42-.068 2.826-.068 4.246 0 1.418-.013 2.824.067 4.244.08 1.647.455 3.107 1.66 4.313 1.206 1.205 2.666 1.58 4.313 1.66 1.42.08 2.826.067 4.246.067 1.42 0 2.826.014 4.245-.067 1.65-.08 3.108-.455 4.314-1.66 1.205-1.206 1.58-2.666 1.66-4.313.08-1.42.067-2.826.067-4.245z"
                            fill-rule="evenodd" id="Instagram" stroke="none"></path>
                    </svg>

                </a>
                <a class="footer__social-media-link" href="https://www.pinterest.at/JunkartCreative" rel="noreferrer"
                    target="_blank">
                    <svg class="footer__social-media-icon footer__pinterest-icon" height="23" viewBox="0 0 19 23"
                        width="19" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M.93 8.424c0 1.755.695 4.313 2.61 4.888.067.014.16.04.228.04.442 0 .696-1.23.696-1.58 0-.415-1.058-1.3-1.058-3.026 0-3.59 2.732-6.134 6.268-6.134 3.04 0 5.29 1.727 5.29 4.9 0 2.372-.95 6.818-4.03 6.818-1.113 0-2.063-.803-2.063-1.955 0-1.688 1.18-3.32 1.18-5.063 0-1.058-.604-1.915-1.73-1.915-1.525 0-2.463 1.715-2.463 3.067 0 .75.094 1.58.43 2.264-.617 2.65-1.876 6.602-1.876 9.334 0 .844.12 1.675.202 2.518l.12.134.188-.067c2.25-3.08 2.17-3.683 3.187-7.714.55 1.045 1.97 1.607 3.094 1.607 4.742 0 6.87-4.62 6.87-8.786C18.07 3.32 14.24.43 10.037.43 5.456.43.93 3.48.93 8.423z"
                            fill-rule="evenodd"></path>
                    </svg>

                </a>
            </div>
        </div>
        <hr class="footer__separator">
        <div class="footer__copyrights--markdown">
            <div id="bottom-bar" class="bottom-bar">
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-1">
                            <div #mlogo>
                                <ng-template>
                                    {{ src }}
                                </ng-template>
                            </div>
                        </div>
                        <div class="col-10 d-flex align-self-center justify-content-end">© Copyright {{currentYear}}.
                            <!-- <b>MadKraftz</b>. -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>