import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css', './../home.component.css']
})
export class BannerComponent implements OnInit {

  q: string = '';
  shopUrl = environment.shopUrl;

  constructor() { }

  ngOnInit(): void {
  }

  shop() {
    window.location.href = this.shopUrl + "/?q=" + this.q;
  }

}
