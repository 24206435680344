<section class="marketplace-lander">
    <div class="coverimage">
        <figure class="marketplace-cover-small fluidratio"></figure>
        <div class="coverimage-fade">
            <figure class="marketplace-cover-small-fade fluidratio"></figure>
        </div>
    </div>
    <div class="title-container">
        <div class="title-header-wrapper">
            <div class="marketplace-title-header">
                <h1>Information about MadKraftz</h1>

            </div>
        </div>
    </div>
</section>

<article class="page-content">
    <div class="wrapper">
        <app-left-menu></app-left-menu>
        <div class="left-navi-section about-section">
            <div data-mercury="full" id="how_to_use_page_content">

                <h1>
                    <b>
                        <font color="#ff7b08">How it works</font>
                    </b>
                </h1>
                <div class="tab-wrap">
                    <input type="radio" id="tab1" name="tabGroup1" class="tab" [checked]="usertype==='buyer'"
                        (click)="changeUserType('buyer')">
                    <label for="tab1">Buyers</label>
                    <input type="radio" id="tab2" name="tabGroup1" class="tab" [checked]="usertype==='creator'"
                        (click)="changeUserType('creator')">
                    <label for="tab2">Creators</label>
                    <input type="radio" id="tab3" name="tabGroup1" class="tab" [checked]="usertype==='designer'"
                        (click)="changeUserType('designer')">
                    <label for="tab3">Architects</label>
                    <ng-template [ngIf]="usertype==='buyer'">
                        <div class="tab__content">
                            <div class="col-12">
                                <div class="row">

                                    <div class="d-flex justify-content-between align-items-center">

                                        <h2 class="mt-3">
                                            <font color="#ff7b08" style="text-transform: uppercase;">Buy Online</font>
                                        </h2>
                                        <div class="align-self-center">
                                            <a class="landing-button--ghost landing-button mx-1" target="_blank"
                                                href="mailto:info@madkraftz.com">Email Us</a>
                                            <a class="landing-button--ghost signup-button mx-1"
                                                href="{{shopUrl}}/en/signup">Sign Up</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div></div>
                            <div>
                                <p>Simply browse our already selected items listed and shop the ones that complete your
                                    grand design. You might like loads
                                    of items and don't know what to choose to best fit your space. Just message us and
                                    we'll offer free interior design
                                    advice to make your life easier!
                                </p>
                            </div>

                            <h2>
                                <font color="#ff7b08" style="text-transform: uppercase;">
                                    Bespoke Orders
                                </font>
                            </h2>
                            <p>You have a keepsake that you want to refinish or upcycle and match what you currently
                                own. We will request some photos
                                of your space and give you a few options to choose from. Just send us a message, we're
                                here to help.</p>

                            <ul style="list-style: none;  padding:0">
                                <li>
                                    <font color="#649fbe"><b>1.&nbsp;&nbsp;</b></font> A few photos of the item, if
                                    damaged, photograph that specific area
                                    closer
                                </li>
                                <li>
                                    <font color="#649fbe"><b>2.&nbsp;</b></font> A few photos of the space you wish to
                                    integrate it&nbsp;in
                                </li>
                                <li>
                                    <font color="#649fbe"><b>3.&nbsp; </b></font> Your contact details: Name, email
                                    address, phone number
                                    (optional).
                                </li>
                                <li>
                                    <font color="#649fbe"><b>4.&nbsp;</b></font> Sign up to receive bespoke
                                    advice,&nbsp;updates on our conversations, design
                                    proposals
                                    and the status of the products we are working on for you.
                                </li>
                                <li>
                                    <font color="#649fbe"><b>5.&nbsp;</b></font>&nbsp;<span
                                        style="background-color: transparent; font-size: 1em;">Don't forget
                                        to&nbsp;rate our services!</span>
                                </li>
                            </ul>

                            <h2 class="col-12 px-0">
                                <font color="#ff7b08" style="text-transform: uppercase;">The Whole Kit and Caboodle
                                </font>
                            </h2>
                            <div>
                                <p>You wish to redesign your
                                    home
                                    or your business (B&amp;B, Restaurant, Art Gallery, Themed Events etc). Send us a
                                    message or give us a ring, we offer bespoke interior design services with options to
                                    reuse what you already have in the space!</p>
                            </div>

                            <ul style="list-style: none;  padding:0">
                                <li>
                                    <font color="#649fbe"><b>1.&nbsp;&nbsp;&nbsp;</b></font>A few photos of your space
                                    and items that are already there
                                </li>
                                <li>
                                    <font color="#649fbe"><b>2.&nbsp;&nbsp;</b></font> A theme that you like, even a
                                    hobby, a little bit about yourself,&nbsp;if you
                                    don't
                                    have any ideas
                                </li>
                                <li>
                                    <font color="#649fbe"><b>3.&nbsp;&nbsp;</b></font>Your contact details: Name, email
                                    address, phone number.
                                </li>
                                <li>
                                    <font color="#649fbe"><b>4.&nbsp;&nbsp;</b></font>Sign up to receive bespoke
                                    advice,&nbsp;updates on our conversations, design
                                    proposals and the products we are working on for you.
                                </li>

                                <li>
                                    <font color="#649fbe"><b>5.&nbsp;&nbsp;</b></font>We get in touch via phone to have
                                    an initial chat about your budget and
                                    ideas
                                </li>
                                <li>
                                    <font color="#649fbe"><b>6.&nbsp;&nbsp;</b></font>We send&nbsp;you a quote for the
                                    interior design project
                                </li>
                                <li>
                                    <font color="#649fbe"><b>7.&nbsp;&nbsp;</b></font>We send&nbsp;you a quote for the
                                    items to be upcycled and our
                                    commision
                                </li>
                                <li>
                                    <font color="#649fbe"><b>8.&nbsp;&nbsp;</b></font>After you approve the designs, you
                                    pay
                                </li>
                                <li>
                                    <font color="#649fbe"><b>9.&nbsp;&nbsp;</b></font>We start working on your items and
                                    update you on the progress, ask questions if
                                    needed
                                </li>
                                <li>
                                    <font color="#649fbe"><b>10.&nbsp;</b></font>You get your precious items carefuly
                                    delivered
                                </li>

                                <li>
                                    <font color="#649fbe"><b>11.&nbsp;</b></font>Don't forget to&nbsp;rate our services!
                                </li>
                            </ul>
                        </div>
                    </ng-template>
                    <ng-template [ngIf]="usertype==='creator'">
                        <div class="tab__content">
                            <div class="col-12">
                                <div class="row">
                                    <div class="d-flex justify-content-between align-items-center">
                            
                                        <h2 class="mt-3">
                                            <font color="#ff7b08" style="text-transform: uppercase;">Join
                                                our&nbsp;community!</font>
                                        </h2>
                                        <div class="align-self-center">
                                            <a class="landing-button--ghost landing-button" target="_blank"
                                                href="https://join.slack.com/t/madkraftz/shared_invite/zt-1hrprx2mc-sfDUOXXQectd~mevsKOz1A">CLICK
                                                HERE TO JOIN NOW!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p>We've been looking at the art and furniture markets for years now and noticed that there
                                has been a gaping hole
                                in the availability of beautiful, one-of-a-kind creations made from materials that would
                                otherwise be thrown
                                away or discarded. That's why we started MadKraftz: to give artists in the UK a place
                                where they can share their
                                work with the UK market.</p>
                            <p>We are looking for
                                talented upcyclers like you, to
                                join our exclusive community! We not only select the best artisans but we also
                                select individual creations
                                that get listed on our marketplace. You'll have access to our messaging program,
                                designed specifically for
                                you to get inspiration, information on topics of interest that are grown
                                organically, advice from other
                                peers or specialists.</p>
                            <p>If you think your
                                creations are beautiful, contact
                                us via our platform and send:</p>
                            <p>
                                <li>
                                    <font color="#649fbe"><b>1.&nbsp;&nbsp;</b></font>A link to your shop if
                                    online or links to where
                                    you advertise your items
                                </li>
                                <li>
                                    <font color="#649fbe"><b>2.&nbsp;&nbsp;</b></font>Photos of the items you
                                    currently&nbsp;have for
                                    sale&nbsp;
                                </li>
                                <li>
                                    <font color="#649fbe"><b>3.&nbsp;&nbsp;</b></font>A portofolio
                                    (optional)
                                </li>
                                <li>
                                    <font color="#649fbe"><b>4.&nbsp;&nbsp;</b></font>A short description
                                    about you, your story, how
                                    and why you chose to work in this field.
                                </li>
                                <li>
                                    <font color="#649fbe"><b>5.&nbsp;&nbsp;</b></font>We will review your
                                    request and get back to you
                                    as soon as possible
                                </li>
                            </p>
                            <p>If your items are picked
                                by our team, you will get
                                notified via email. When this happens, you will be onboarded onto our Slack messaging
                                program, designed specifically for you to
                                get:</p>


                            <h2>
                                <font color="#ff7b08">Concierge</font>
                            </h2>
                            <p>This is the channel where you ask ANY&nbsp;questions you have regarding what we do, how
                                we do it and why. We
                                will be here to guide you either to the right channel or help out hands on, just like a
                                concierge would.</p>

                            <h2>
                                <font color="#ff7b08">Improvements</font>
                            </h2>
                            <p>We want to improve in everything that we do and wish to remove any impediments that
                                you might have in order
                                to do what you like best, creating. Because of this, feedback is really important
                                for how we will develop
                                the platform moving forward. So don't be shy, there is no bad idea, just better
                                ideas.</p>

                            <h2>
                                <font color="#ff7b08">General</font>
                            </h2>
                            <p>Here you will have access to general announcements regarding our platform and services,
                                advertisments we create
                                and any changes that could occur.</p>

                            <h2>
                                <font color="#ff7b08">How to</font>
                            </h2>
                            <p>We all learn new things every day, here is your chance to learn from your peers about
                                how to do something you
                                haven't done before.</p>

                            <h2>
                                <font color="#ff7b08">Inspiration</font>
                            </h2>
                            <p>We all have times when inspiration just doesn't come. That's how our community is here
                                to help.&nbsp;Besides
                                our Pinterest account that will give you plenty of inspiration, the concierge will also
                                help out by with giving
                                you tailored ideas about products&nbsp;you are working on.</p>

                            <h2>
                                <font color="#ff7b08">New clients</font>
                            </h2>
                            <p class="col-12">This is a space where we
                                add possible clients and
                                actual clients
                                notifications.
                            </p>

                            <h2>
                                <font color="#ff7b08">Creators</font>
                            </h2>
                            <p class="col-12">Here is a space where we
                                enquire about
                                availability to pick up a new project for a client. You send your moodboards that
                                end up with the client.
                                The artist chosen will be connected to the client on our platform&nbsp;in order to
                                work closely
                                together.</p>

                            <h2>
                                <font color="#ff7b08">Architects</font>
                            </h2>
                            <p class="col-12">
                                Whenever a new client writes to us, asking for free advice, you will see it here. Based
                                on your ranking for free advice
                                and your availability at the time we get the order, you will have priority to make a
                                bespoke project for a house or
                                business.
                            </p>
                            <h2>
                                <font color="#ff7b08">Witty</font>
                            </h2>
                            <p class="col-12">This is just a space for
                                random stuff, jokes,
                                anything unrelated to our other channels.</p>
                        </div>
                    </ng-template>
                    <ng-template [ngIf]="usertype==='designer'">
                        <div class="tab__content">
                            <div class="col-12">
                                <div class="row">
                                    <div class="d-flex justify-content-between align-items-center">

                                        <h2 class="mt-3">
                                            <font color="#ff7b08" style="text-transform: uppercase;">Join
                                                our&nbsp;community!</font>
                                        </h2>
                                        <div class="align-self-center">
                                            <a class="landing-button--ghost landing-button" target="_blank"
                                                href="https://join.slack.com/t/madkraftz/shared_invite/zt-1hrprx2mc-sfDUOXXQectd~mevsKOz1A">CLICK
                                                HERE TO JOIN NOW!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p>If you've ever admired vintage items&nbsp;at an antique store and thought it would be
                                fascinating&nbsp;to design an entire house using just&nbsp;unique pieces&nbsp;— we'd
                                love to
                                hear from you! We're currently looking for talented interior designers who are ready to
                                join
                                our community and our creative&nbsp;projects for our clients. Our team is here to guide
                                you
                                on how this works!</p>

                        </div>
                    </ng-template>
                </div>
            </div>
        </div>

    </div>
</article>