
<section class="marketplace-lander">
    <div class="coverimage">
        <figure class="marketplace-cover-small fluidratio"></figure>
        <div class="coverimage-fade">
            <figure class="marketplace-cover-small-fade fluidratio"></figure>
        </div>
    </div>
    <div class="title-container">
        <div class="title-header-wrapper">
            <div class="marketplace-title-header">
                <h1>
                    <span class="profile-title">Paula U</span>
                </h1>

            </div>
        </div>
    </div>
</section>
<article class="page-content">
    <div class="wrapper">

        <div class="row">
            <div class="col-8">
                <div class="row relative">
                    <div class="people-image-mobile">
                        <img alt="Paula U"
                            src="https://assets1.sharetribe.com/assets/profile_image/medium/missing-c54f2181aa6b87c9c74bbe1ed8876086c314ffcf318441f2f7d70daf736fc3f2.png">
                    </div>
                    <div class="profile-action-buttons-mobile">
                        <a class="profile-edit-link" href="/en/paulau1/settings">
                            <div class="content">
                                Edit profile
                            </div>
                        </a>

                        <div class="admin-actions">
                            <label>Admin actions</label>
                            <a class="profile-edit-link" href="/en/paulau1/settings">
                                <div class="content">
                                    Edit profile
                                </div>
                            </a>
                            <a class="profile-edit-link" href="/en/paulau1/listings/new">
                                <div class="content">
                                    Post listing as
                                </div>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="row profile-about-me">
                    <a href="/en/paulau1/settings">
                        <div class="icon-with-text-container">
                            <div class="ss-chat icon-part"></div>
                            <div class="ss text-part">Share something about yourself</div>
                        </div>
                    </a>
                </div>
                <div class="row">
                    <h2 class="people-header">
                        No open listings
                        <span class="people-show-closed-link">
                            <a href="/en/paulau1/settings/listings">Manage all my listings</a>
                        </span>
                    </h2>
                </div>
                <div id="profile-listings-list">
                    <div class="people-fluid-thumbnail-grid-container">
                        <div class="people-fluid-thumbnail-grid" id="profile-listings-list">

                        </div>
                    </div>

                </div>
                <div class="row">
                    <h2 class="people-header">
                        No followed people
                    </h2>
                </div>
                <div class="people-fluid-thumbnail-grid-container">
                    <div class="people-fluid-thumbnail-grid" id="profile-followed-people-list">

                    </div>
                </div>

                <div class="listing-main" id="people-testimonials">
                    <div class="row">
                        <h2 class="people-header">
                            No reviews
                        </h2>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="people-image">
                        <img alt="Paula U"
                            src="https://assets1.sharetribe.com/assets/profile_image/medium/missing-c54f2181aa6b87c9c74bbe1ed8876086c314ffcf318441f2f7d70daf736fc3f2.png">
                    </div>
                </div>
                <div class="row">
                    <div class="profile-action-buttons-desktop">
                        <a class="profile-edit-link" href="/en/paulau1/settings">
                            <div class="content">
                                Edit profile
                            </div>
                        </a>

                        <div class="admin-actions">
                            <label>Admin actions</label>
                            <a class="profile-edit-link" href="/en/paulau1/settings">
                                <div class="content">
                                    Edit profile
                                </div>
                            </a>
                            <a class="profile-edit-link" href="/en/paulau1/listings/new">
                                <div class="content">
                                    Post listing as
                                </div>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</article>