import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-listing',
  templateUrl: './new-listing.component.html',
  styleUrls: ['./new-listing.component.css']
})
export class NewListingComponent implements OnInit {

  category!:string;
  constructor() { }

  ngOnInit(): void {
  }

  selectedCategory(selectedCategory:string) {
    this.category = selectedCategory;
  }

}
