<div class="row m-0">
<img-comparison-slider [value]="100" class="col-12 col-lg-7 p-0">
    <img slot="first" width="100%" src="./../../../../assets/images/banner/idea-before.jpg" />
    <img slot="second" width="100%" src="./../../../../assets/images/banner/idea-after.jpg" />
    <svg slot="handle" class="custom-animated-handle" xmlns="http://www.w3.org/2000/svg" width="100"
        viewBox="-8 -3 16 6">
        <path stroke="#fff" d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2" stroke-width="1"
            fill="#fff" vector-effect="non-scaling-stroke"></path>
    </svg>
</img-comparison-slider>
<!-- <img class="col-5 p-0" src="./../../../../assets/images/banner/search.png"> -->
<section id="banner" class="banner-section col-12 col-lg-5 p-0 m-0">
    <div class="banner-content">
        <div class="banner-content-container">

            <div class="banner-search-bar-padding">
                <form method="get" id="banner__search-form" class="banner-search-bar" action="/">
                    <div class="banner-search-input-container">
                        <!-- <button type="submit" class="banner-search-icon" (click)="shop()">

                            <svg class="banner-search-icon-svg" viewBox="27 19 22 22" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="icon_search" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                                    transform="translate(38.000000, 30.000000) scale(-1, 1) translate(-38.000000, -30.000000) translate(28.000000, 20.000000)"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M14.6666667,14.6666667 L19.3254458,19.3254458" id="line" stroke="#525961"
                                        stroke-width="2"></path>
                                    <circle id="Oval" stroke="#525961" stroke-width="2" cx="8" cy="8" r="8"></circle>
                                </g>
                            </svg>
                        </button> -->
                        <input type="search" name="q" class="banner-search-input" [(ngModel)]="q"
                            placeholder="Furniture, Lights, Art & Decor...">
                    </div>
                    <button type="submit" class="banner-search-button" (click)="shop()">SHOP<br/>
                        HERE!</button>
                </form>
            </div>

        </div>
    </div>
</section>
</div>



<!-- <section id="banner" class="banner-section">
    <div class="banner-content">
        <div class="banner-content-container">

            <div class="banner-search-bar-padding">
                <form method="get" id="banner__search-form" class="banner-search-bar" action="/">
                    <div class="banner-search-input-container">
                        <button type="submit" class="banner-search-icon" (click)="shop()">

                            <svg class="banner-search-icon-svg" viewBox="27 19 22 22" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="icon_search" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                                    transform="translate(38.000000, 30.000000) scale(-1, 1) translate(-38.000000, -30.000000) translate(28.000000, 20.000000)"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M14.6666667,14.6666667 L19.3254458,19.3254458" id="line" stroke="#525961"
                                        stroke-width="2"></path>
                                    <circle id="Oval" stroke="#525961" stroke-width="2" cx="8" cy="8" r="8"></circle>
                                </g>
                            </svg>
                        </button>
                        <input type="search" name="q" class="banner-search-input"
                            placeholder="furniture, lights, décor">
                    </div>
                    <button type="submit" class="banner-search-button banner-search-button" (click)="shop()">SHOP
                        HERE</button>

                </form>
            </div>

        </div>
    </div>
</section> -->