import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'madkraftz';

  @ViewChild('top')
  topScroll!: ElementRef;

  constructor() { }

  onActivate(event: any) {
    this.topScroll.nativeElement.scrollIntoView('smooth');
  }

}
