import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-featured',
  templateUrl: './featured.component.html',
  styleUrls: ['./featured.component.css', './../home.component.css']
})
export class FeaturedComponent implements OnInit {

  shopUrl = environment.shopUrl;
  constructor() { }

  ngOnInit(): void {
  }

}
