import { Role } from './role';

export class User {
  userId!: number;
  email!: string;
  firstName!: string;
  lastName!: string;
  avatar!: string;
  roles!: string[];
  displayName!: string;
  status!: string;
  token?: string;
}
