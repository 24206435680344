import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-decor',
  templateUrl: './decor.component.html',
  styleUrls: ['./decor.component.css', './../home.component.css']
})
export class DecorComponent implements OnInit {

  shopUrl = environment.shopUrl;
  constructor() { }

  ngOnInit(): void {
  }

}
