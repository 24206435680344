<section class="marketplace-lander">
    <div class="coverimage">
        <figure class="marketplace-cover-small fluidratio"></figure>
        <div class="coverimage-fade">
            <figure class="marketplace-cover-small-fade fluidratio"></figure>
        </div>
    </div>
    <div class="title-container">
        <div class="title-header-wrapper">
            <div class="marketplace-title-header">
                <h1>Inbox</h1>

            </div>
        </div>
    </div>
</section>
<article class="page-content">
    <div class="wrapper">

        <div class="undo-mobile-wrapper-margin" id="conversations">
            <div class="conversation-row row-with-divider without-margin">
                <div class="col-3">
                    <div class="conversation-details-container">
                        <a href="/en/deleted_c779a5494f"><img class="conversation-avatar"
                                src="https://assets1.sharetribe.com/assets/profile_image/thumb/missing-2103b8402da301387627aaea4ce27451dedbd0f6aa0cf7850759a0a8b368375e.png">
                        </a>
                        <div class="conversation-details">
                            <div class="conversation-other-party-name">
                                Removed user
                            </div>
                            <div class="conversation-last-message-at">
                                3 months ago
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <a class="conversation-title-link" href="/en/paulau1/transactions/885627">Hi,

                        Can you please help?

                        Kind regards,
                        X
                    </a>
                    <div class="conversation-context">
                        About listing Sideboard - Boho Grunge
                    </div>
                </div>
                <div class="col-3">
                </div>
            </div>



            <div id="pageless-loader" style="display:none;text-align:center;width:100%;">
                <div class="msg" style="color:#e9e9e9;font-size:2em">Loading more messages</div> <img
                    src="https://s3.amazonaws.com/sharetribe/assets/ajax-loader-grey.gif" alt="loading more results"
                    style="margin:10px auto">
            </div>
        </div>

    </div>
</article>