<div id="root" class="">
    <nav class="topnav">
        <div class="logo">
            <a class="logo" href="/en/admin"><img width="151" alt="Sharetribe Go Admin Panel"
                    src="https://assets2.sharetribe.com/assets/gap-logo-669120982e1114e36c7488ac421d2ad7141d92aad7f31661cd50221366e6f462.png">
            </a>
        </div>
        <div class="mobilemenu-button" id="sidebarCollapse">
            <img
                src="https://assets2.sharetribe.com/assets/svg/hamburger-menu-be62b311e507bdbb563bad3eb38aeaaa53755ba88c0a8995f28b4bc9c260ea1a.svg">
            <span>
                Menu
            </span>
        </div>
        <div class="mobilemenu-top-home">
            <a href="/en/admin"><svg height="17" width="18" xmlns="http://www.w3.org/2000/svg">
                    <g fill-rule="evenodd" fill="#D8DCE6" stroke-linecap="round" stroke-linejoin="round"
                        stroke="#484B4A">
                        <path
                            d="M9.03519648 1.2220566c.30285853.00100529.59290483.12232442.80628167.33725373l7.18580605 7.18047928-2.2832951-.00073914.0003958 6.84943703H3.32988837l-.00089931-6.84943703-2.282.00073914 2.282-2.28473914.00089931-.00044306.00810069-.00855694 4.88871832-4.8920669c.21479019-.21351689.50563058-.3329198.8084891-.33192696z">
                        </path>
                        <path
                            d="M11 14.8812393v-3c0-1.10456953-.8954305-2.00000003-2-2.00000003s-2 .8954305-2 2.00000003v3">
                        </path>
                    </g>
                </svg>
                <span class="hide-small-mobile">Home</span>
            </a>
        </div>
        <div class="topnav-list">
            <div class="nav-item" data-intercom-target="Go to your marketplace">
                <a class="nav-link" href="/en"><span class="hide-mobile">
                        Go to your
                    </span>
                    Marketplace
                </a>
            </div>

            <div class="nav-item dropdown avatar-menu">
                <a aria-expanded="false" aria-haspopup="true" class="nav-link" data-toggle="dropdown" href="#"
                    id="header-user-desktop-anchor" role="button">
                    <img class="user-avatar" alt="Sharetribe Go Admin"
                        src="https://assets1.sharetribe.com/assets/profile_image/thumb/missing-2103b8402da301387627aaea4ce27451dedbd0f6aa0cf7850759a0a8b368375e.png">
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="/en/logout">Log out</a>
                </div>
            </div>

        </div>
    </nav>


    <div class="layout-container">
        <aside class="sidenav-container">
            <div class="sidenav-container-top" data-intercom-target="Admin menu">
                <!-- Title for the Admin Panel -->
                <h6>
                    Admin menu
                </h6>
                <div class="sidenav-links">
                    <!-- Dashboard -->
                    <ul class="category-title">
                        <li class="active" id="dashboard">
                            <svg height="17" width="18" xmlns="http://www.w3.org/2000/svg">
                                <g fill-rule="evenodd" fill="#D8DCE6" stroke-linecap="round" stroke-linejoin="round"
                                    stroke="#484B4A">
                                    <path
                                        d="M9.03519648 1.2220566c.30285853.00100529.59290483.12232442.80628167.33725373l7.18580605 7.18047928-2.2832951-.00073914.0003958 6.84943703H3.32988837l-.00089931-6.84943703-2.282.00073914 2.282-2.28473914.00089931-.00044306.00810069-.00855694 4.88871832-4.8920669c.21479019-.21351689.50563058-.3329198.8084891-.33192696z">
                                    </path>
                                    <path
                                        d="M11 14.8812393v-3c0-1.10456953-.8954305-2.00000003-2-2.00000003s-2 .8954305-2 2.00000003v3">
                                    </path>
                                </g>
                            </svg>
                            <a href="/en/admin">Home</a>
                        </li>
                    </ul>
                    <!-- General -->
                    <ul class="category-title">
                        <li>
                            <svg height="17" width="17" xmlns="http://www.w3.org/2000/svg">
                                <g fill-rule="evenodd" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                    stroke="#484B4A" transform="translate(.059)">
                                    <path
                                        d="M7.178 1.66a1.332 1.332 0 001.98 0l.644-.707a1.333 1.333 0 012.32.962l-.048.953a1.333 1.333 0 001.397 1.398l.952-.049a1.333 1.333 0 01.96 2.322l-.71.64a1.334 1.334 0 000 1.98l.71.64a1.333 1.333 0 01-.962 2.32l-.953-.048a1.333 1.333 0 00-1.399 1.4l.048.952a1.333 1.333 0 01-2.315.96l-.64-.708a1.333 1.333 0 00-1.98 0l-.643.708a1.333 1.333 0 01-2.319-.958l.05-.953a1.333 1.333 0 00-1.4-1.4l-.953.049A1.333 1.333 0 01.953 9.8l.709-.64a1.334 1.334 0 000-1.98l-.71-.642a1.333 1.333 0 01.96-2.319l.953.048a1.333 1.333 0 001.4-1.402l-.045-.953A1.333 1.333 0 016.54.953l.64.706z">
                                    </path>
                                    <path
                                        d="M7.178 1.66a1.332 1.332 0 001.98 0l.644-.707a1.333 1.333 0 012.32.962l-.048.953a1.333 1.333 0 001.397 1.398l.952-.049a1.333 1.333 0 01.96 2.322l-.71.64a1.334 1.334 0 000 1.98l.71.64a1.333 1.333 0 01-.962 2.32l-.953-.048a1.333 1.333 0 00-1.399 1.4l.048.952a1.333 1.333 0 01-2.315.96l-.64-.708a1.333 1.333 0 00-1.98 0l-.643.708a1.333 1.333 0 01-2.319-.958l.05-.953a1.333 1.333 0 00-1.4-1.4l-.953.049A1.333 1.333 0 01.953 9.8l.709-.64a1.334 1.334 0 000-1.98l-.71-.642a1.333 1.333 0 01.96-2.319l.953.048a1.333 1.333 0 001.4-1.402l-.045-.953A1.333 1.333 0 016.54.953l.64.706z"
                                        fill="#D8DCE6"></path>
                                    <circle cx="8.168" cy="8.169" fill="#FFF" r="3.063"></circle>
                                </g>
                            </svg>
                            <a class="dropdown-toggle" aria-controls="general" aria-expanded="false"
                                data-toggle="collapse" role="button" href="#general">General</a>
                        </li>
                    </ul>
                    <ul class="collapse subtitle" id="general">
                        <li id="essentials">
                            <a href="/en/admin/general/essentials">Essentials</a>
                        </li>
                        <li id="domain">
                            <a href="/en/admin/general/domain">Domain</a>
                        </li>
                        <li id="privacy">
                            <a href="/en/admin/general/privacy">Privacy</a>
                        </li>
                        <li id="staticcontent-mobile">
                            <a href="/en/admin/general/static-content">Static content</a>
                        </li>
                        <li id="adminnotifications-mobile">
                            <a href="/en/admin/general/admin-notifications">Admin notifications</a>
                        </li>
                    </ul>
                    <!-- Design -->
                    <ul class="category-title">
                        <li>
                            <svg height="18" width="16" xmlns="http://www.w3.org/2000/svg">
                                <g fill-rule="evenodd" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                    stroke="#484B4A">
                                    <path
                                        d="M14.4117646 17.2352937H.64705877c.69575824-1.3917931 1.0582503-2.9263429 1.05882352-4.4823529v-.8117647H13.3529411v.8117647c.0005732 1.55601.3630653 3.0905598 1.0588235 4.4823529h0z"
                                        fill="#D8DCE6"></path>
                                    <path
                                        d="M9.33576465 6.61741135h0a16.0764706 16.0764706 0 00.31129411-3.14682353h0c0-1.16954417-.94810288-2.11764705-2.11764705-2.11764705h0c-1.16954418 0-2.11764706.94810288-2.11764706 2.11764705h0a16.0764706 16.0764706 0 00.31129411 3.14682353h0c.05351069.26732651-.01566992.54456088-.18850275.755406-.17283284.21084511-.43110303.33307999-.70373254.33306459H2.76470582c-1.16954417 0-2.11764706.94810288-2.11764706 2.11764706v1.5882353c0 .292386.23702572.5294118.52941177.5294118H13.8823529c.292386 0 .5294117-.2370258.5294117-.5294118V9.823529c0-1.16954418-.9481028-2.11764706-2.117647-2.11764706h-2.0661177c-.27262947.0000154-.53089966-.12221948-.7037325-.33306459-.17283283-.21084512-.24201343-.48807949-.18850275-.755406z"
                                        fill="#D8DCE6"></path>
                                    <path d="M11.2352941 17.2352937v-2.6470588M9.117647 17.2352937v-2.1176471"
                                        fill="#FFF"></path>
                                </g>
                            </svg>
                            <a class="dropdown-toggle" aria-controls="design" aria-expanded="false"
                                data-toggle="collapse" role="button" href="#design">Design</a>
                        </li>
                    </ul>
                    <ul class="collapse subtitle" id="design">
                        <li id="logoscolor">
                            <a href="/en/admin/design/logos-and-color">Logos &amp; Color</a>
                        </li>
                        <li id="coverphotos">
                            <a href="/en/admin/design/cover-photos">Cover photos</a>
                        </li>
                        <li id="landingpage">
                            <a href="/en/admin/design/landing-page">Landing page</a>
                        </li>
                        <li id="topbar">
                            <a href="/en/admin/design/top-bar">Top bar</a>
                        </li>
                        <li id="footer">
                            <a href="/en/admin/design/footer">Footer</a>
                        </li>
                        <li id="display">
                            <a href="/en/admin/design/arrangement">Arrangement</a>
                        </li>
                    </ul>
                    <!-- Divider -->
                    <hr>
                    <!-- Users -->
                    <ul class="category-title">
                        <li>
                            <svg height="15" width="19" xmlns="http://www.w3.org/2000/svg">
                                <g fill-rule="evenodd" fill="#D8DCE6" stroke-linecap="round" stroke-linejoin="round"
                                    stroke="#484B4A" transform="translate(.596 .734)">
                                    <circle cx="13.235" cy="5.162" r="2.647"></circle>
                                    <path d="M8.934 13.103a4.235 4.235 0 118.47 0h-8.47z"></path>
                                    <circle cx="5.36" cy="3.276" r="3.276"></circle>
                                    <path d="M0 13.103a5.36 5.36 0 1110.72 0H0z"></path>
                                </g>
                            </svg>
                            <a class="dropdown-toggle" aria-controls="users" aria-expanded="false"
                                data-toggle="collapse" role="button" href="#users">Users</a>
                        </li>
                    </ul>
                    <ul class="collapse subtitle" id="users">
                        <li id="manageusers">
                            <a href="/en/admin/users/manage-users?direction=desc&amp;sort=join_date">Manage users</a>
                        </li>
                        <li id="userfields">
                            <a href="/en/admin/users/user-fields">User fields</a>
                        </li>
                        <li id="signuplogin">
                            <a href="/en/admin/users/signup-and-login">Signup &amp; Login</a>
                        </li>
                        <li id="userrights">
                            <a href="/en/admin/users/user-rights">User rights</a>
                        </li>
                        <li id="invitations">
                            <a href="/en/admin/users/view-invitations?direction=desc&amp;sort=started">View
                                invitations</a>
                        </li>
                    </ul>
                    <!-- Listings -->
                    <ul class="category-title">
                        <li>
                            <svg height="18" width="17" xmlns="http://www.w3.org/2000/svg">
                                <g fill-rule="evenodd" fill="none">
                                    <g stroke-linecap="round" stroke-linejoin="round" stroke="#484B4A"
                                        transform="translate(.059 .53)">
                                        <rect fill="#D8DCE6" height="13.765" rx="1.5" width="13.765" x="2.647" y=".527">
                                        </rect>
                                        <path d="M14.294 16.409H4.075c-2.676 0-3.546-.913-3.546-2.978V2.644"></path>
                                        <path d="M5.824 4.762h7.411M5.824 6.88h7.411M5.824 8.997h5.294" fill="#D8DCE6">
                                        </path>
                                    </g>
                                </g>
                            </svg>
                            <a class="dropdown-toggle" aria-controls="listings" aria-expanded="false"
                                data-toggle="collapse" role="button" href="#listings">Listings</a>
                        </li>
                    </ul>
                    <ul class="collapse subtitle" id="listings">
                        <li id="managelistings">
                            <a href="/en/admin/listings/manage-listings?direction=desc&amp;sort=updated">Manage
                                listings</a>
                        </li>
                        <li id="categories">
                            <a href="/en/admin/listings/categories">Categories</a>
                        </li>
                        <li id="ordertypes">
                            <a href="/en/admin/listings/order-types">Order types</a>
                        </li>
                        <li id="listingfields">
                            <a href="/en/admin/listings/listing_fields">Listing fields</a>
                        </li>
                        <li id="listingapproval">
                            <a href="/en/admin/listings/listing-approval">Listing approval</a>
                        </li>
                        <li id="listingcomments">
                            <a href="/en/admin/listings/listing-comments">Listing comments</a>
                        </li>
                    </ul>
                    <!-- Transactions -->
                    <ul class="category-title">
                        <li>
                            <svg height="18" width="18" xmlns="http://www.w3.org/2000/svg">
                                <g fill-rule="evenodd" fill="#D8DCE6" stroke-linecap="round" stroke-linejoin="round"
                                    stroke="#484B4A">
                                    <path
                                        d="M6.412 16.772H1.647a1.059 1.059 0 01-1.059-1.058V4.596c0-.585.474-1.059 1.06-1.059h2.646a2.647 2.647 0 115.294 0h2.647c.585 0 1.06.474 1.06 1.059v1.588M3.765 6.174h5.294M3.765 8.29H8">
                                    </path>
                                    <path
                                        d="M12.727 8.605l1.088 2.873h2.118a.519.519 0 01.365.906l-1.835 1.281 1.018 2.34a.555.555 0 01-.79.693l-2.463-1.385-2.461 1.385a.555.555 0 01-.79-.694l1.019-2.339-1.836-1.281a.518.518 0 01.364-.906h2.117l1.09-2.873a.565.565 0 01.996 0zM6.941 3.008a.265.265 0 110 .53.265.265 0 010-.53">
                                    </path>
                                </g>
                            </svg>
                            <a class="dropdown-toggle" aria-controls="transactionsreviews" aria-expanded="false"
                                data-toggle="collapse" role="button" href="#transactionsreviews">Transactions &amp;
                                Reviews</a>
                        </li>
                    </ul>
                    <ul class="collapse subtitle" id="transactionsreviews">
                        <li id="managetransactions">
                            <a
                                href="/en/admin/transactions-and-reviews/manage-transactions?direction=desc&amp;sort=last_activity">Manage
                                transactions</a>
                        </li>
                        <li id="viewconversations">
                            <a
                                href="/en/admin/transactions-and-reviews/view-conversations?direction=desc&amp;sort=last_activity">View
                                conversations</a>
                        </li>
                        <li id="managereviews">
                            <a href="/en/admin/transactions-and-reviews/manage-reviews">Manage reviews</a>
                        </li>
                        <li id="configuretransactions">
                            <a href="/en/admin/transactions-and-reviews/configure-transactions">Configure
                                transactions</a>
                        </li>
                    </ul>
                    <!-- Divider -->
                    <hr>
                    <!-- Payment system -->
                    <ul class="category-title">
                        <li>
                            <svg height="12" style="top: 0px;" width="17" xmlns="http://www.w3.org/2000/svg">
                                <g fill-rule="evenodd" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                    stroke="#484B4A" transform="translate(.059 .353)">
                                    <rect fill="#D8DCE6" height="10.21" rx="1.5" width="15.315" x=".511" y=".34"></rect>
                                    <circle cx="8.168" cy="5.445" r="2.042"></circle>
                                    <path
                                        d="M3.063 2.638a.255.255 0 110 .51.255.255 0 010-.51M13.273 7.743a.255.255 0 110 .51.255.255 0 010-.51">
                                    </path>
                                </g>
                            </svg>
                            <a class="dropdown-toggle" aria-controls="paymentsystem" aria-expanded="false"
                                data-toggle="collapse" role="button" href="#paymentsystem">Payment system</a>
                        </li>
                    </ul>
                    <ul class="collapse subtitle" id="paymentsystem">
                        <li id="countrycurrency">
                            <a href="/en/admin/payment-system/country-currency">Country &amp; Currency</a>
                        </li>
                        <li id="transactionsize">
                            <a href="/en/admin/payment-system/minimum-listing-price">Minimum listing price</a>
                        </li>
                        <li id="paypal">
                            <a href="/en/admin/payment-system/paypal">PayPal settings</a>
                        </li>
                        <li id="stripe">
                            <a href="/en/admin/payment-system/stripe">Stripe settings</a>
                        </li>
                    </ul>
                    <!-- Email -->
                    <ul class="category-title">
                        <li>
                            <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg">
                                <g fill-rule="evenodd" fill="#D8DCE6" stroke-linecap="round" stroke-linejoin="round"
                                    stroke="#484B4A">
                                    <path
                                        d="M1.885 10.402A1.101 1.101 0 011.3 8.367L13.532.72a1.1 1.1 0 011.665 1.138l-2.382 12.688a1.101 1.101 0 01-1.963.457l-3.448-4.602h-5.52z">
                                    </path>
                                    <path
                                        d="M7.404 10.402H5.75v3.422a1.1 1.1 0 001.72.909l2.104-1.434-2.17-2.897zM7.404 10.402L14.736.747">
                                    </path>
                                </g>
                            </svg>
                            <a class="dropdown-toggle" aria-controls="emails" aria-expanded="false"
                                data-toggle="collapse" role="button" href="#emails">Emails</a>
                        </li>
                    </ul>
                    <ul class="collapse subtitle" id="emails">
                        <li id="emailusers">
                            <a href="/en/admin/emails/compose-email">Compose email</a>
                        </li>
                        <li id="welcomeemail">
                            <a href="/en/admin/emails/welcome-email">Welcome email</a>
                        </li>
                        <li id="automaticnewsletter">
                            <a href="/en/admin/emails/automatic-newsletter">Automatic newsletter</a>
                        </li>
                        <li id="customoutgoingaddress">
                            <a href="/en/admin/emails/custom-outgoing-address">Custom outgoing address</a>
                        </li>
                    </ul>
                    <!-- Search & Location -->
                    <ul class="category-title">
                        <li>
                            <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg">
                                <g fill-rule="evenodd" fill="#D8DCE6" stroke-linecap="round" stroke-linejoin="round"
                                    stroke="#484B4A" transform="translate(1.118 1)">
                                    <path d="M8.801 8.868l4.964 4.963" stroke-width="1.75"></path>
                                    <ellipse cx="6.022" cy="5.559" rx="5.824" ry="5.294"></ellipse>
                                </g>
                            </svg>
                            <a class="dropdown-toggle" aria-controls="searchlocation" aria-expanded="false"
                                data-toggle="collapse" role="button" href="#searchlocation">Search &amp; Location</a>
                        </li>
                    </ul>
                    <ul class="collapse subtitle" id="searchlocation">
                        <li id="search">
                            <a href="/en/admin/search-and-location/search">Search</a>
                        </li>
                        <li id="location">
                            <a href="/en/admin/search-and-location/location">Location</a>
                        </li>
                    </ul>
                    <!-- Social media -->
                    <ul class="category-title">
                        <li>
                            <svg height="17" width="17" xmlns="http://www.w3.org/2000/svg">
                                <g fill-rule="evenodd" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                    stroke="#484B4A" transform="translate(1.118 .824)">
                                    <circle cx="2.779" cy="8.338" fill="#D8DCE6" r="2.779"></circle>
                                    <circle cx="12.044" cy="12.044" fill="#D8DCE6" r="2.779"></circle>
                                    <circle cx="12.044" cy="2.779" fill="#D8DCE6" r="2.779"></circle>
                                    <path d="M5.157 6.911l4.509-2.705M5.359 9.371l4.106 1.642"></path>
                                </g>
                            </svg>
                            <a class="dropdown-toggle" aria-controls="socialmedia" aria-expanded="false"
                                data-toggle="collapse" role="button" href="#socialmedia">Social media</a>
                        </li>
                    </ul>
                    <ul class="collapse subtitle" id="socialmedia">
                        <li id="imagetags">
                            <a href="/en/admin/social-media/image-and-tags">Image &amp; Tags</a>
                        </li>
                        <li id="twitter">
                            <a href="/en/admin/social-media/twitter">Twitter</a>
                        </li>
                    </ul>
                    <!-- SEO -->
                    <ul class="category-title">
                        <li>
                            <svg height="17" width="17" xmlns="http://www.w3.org/2000/svg">
                                <g fill-rule="evenodd" fill="#D8DCE6" stroke-linecap="round" stroke-linejoin="round"
                                    stroke="#484B4A" transform="translate(.059 .706)">
                                    <path
                                        d="M14.4 5.462V1.49a.993.993 0 00-.993-.993H1.496a.993.993 0 00-.993.993v10.92c0 .547.444.992.993.992h3.97M.503 3.476H14.4">
                                    </path>
                                    <path d="M15.393 15.388l-2.507-2.506" stroke-width="1.5"></path>
                                    <circle cx="10.429" cy="10.425" r="3.474"></circle>
                                </g>
                            </svg>
                            <a class="dropdown-toggle" aria-controls="seo" aria-expanded="false" data-toggle="collapse"
                                role="button" href="#seo">SEO</a>
                        </li>
                    </ul>
                    <ul class="collapse subtitle" id="seo">
                        <li id="sitemaprobots">
                            <a href="/en/admin/seo/sitemap-and-robots">Sitemap &amp; Robots</a>
                        </li>
                        <li id="landingpagemeta">
                            <a href="/en/admin/seo/landing-page-meta-tags">Landing page meta tags</a>
                        </li>
                        <li id="searchresultspagesmeta">
                            <a href="/en/admin/seo/search-page-meta-tags">Search page meta tags</a>
                        </li>
                        <li id="listingpagesmeta">
                            <a href="/en/admin/seo/listing-pages-meta-tags">Listing pages meta tags</a>
                        </li>
                        <li id="categorypagesmeta">
                            <a href="/en/admin/seo/category-pages-meta-tags">Category pages meta tags</a>
                        </li>
                        <li id="profilepagesmeta">
                            <a href="/en/admin/seo/profile-pages-meta-tags">Profile pages meta tags</a>
                        </li>
                        <li id="googlesearchconsole">
                            <a href="/en/admin/seo/google-search-console">Google Search Console</a>
                        </li>
                    </ul>
                    <!-- Analytics -->
                    <ul class="category-title">
                        <li>
                            <svg height="17" width="17" xmlns="http://www.w3.org/2000/svg">
                                <g fill-rule="evenodd" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                    stroke="#484B4A">
                                    <path d="M.58823576 14.8235296H16.4705887"></path>
                                    <path
                                        d="M4.29411812 7.41176494H2.17647106c-.29238605 0-.52941177.23702572-.52941177.52941177v6.88235289h3.17647059V7.94117671c0-.29238605-.23702572-.52941177-.52941176-.52941177zM9.58823576 1.05882376H7.47058871c-.29238605 0-.52941177.23702572-.52941177.52941177V14.8235296h3.17647056V1.58823553c0-.29238605-.23702569-.52941177-.52941174-.52941177zM14.8823534 4.23529435h-2.117647c-.2923861 0-.5294118.23702572-.5294118.52941177V14.8235296h3.1764706V4.76470612c0-.29238605-.2370257-.52941177-.5294118-.52941177z"
                                        fill="#D8DCE6"></path>
                                </g>
                            </svg>
                            <a class="dropdown-toggle" aria-controls="analytics" aria-expanded="false"
                                data-toggle="collapse" role="button" href="#analytics">Analytics</a>
                        </li>
                    </ul>
                    <ul class="collapse subtitle" id="analytics">
                        <li id="googleanalytics">
                            <a href="/en/admin/analytics/google-analytics">Google Analytics</a>
                        </li>
                        <li id="googletagmanager">
                            <a href="/en/admin/analytics/google-tag-manager">Google Tag Manager</a>
                        </li>
                        <li id="sharetribeanalytics">
                            <a href="/en/admin/analytics/sharetribe-analytics">Sharetribe analytics</a>
                        </li>
                    </ul>
                    <!-- Advanced -->
                    <ul class="category-title">
                        <li>
                            <svg height="17" width="16" xmlns="http://www.w3.org/2000/svg">
                                <g fill-rule="evenodd" fill="#D8DCE6" stroke-linecap="round" stroke-linejoin="round"
                                    stroke="#484B4A" transform="translate(.058824 .470588)">
                                    <rect height="14.8897059" rx="1.5" width="14.8897059" x=".49698529" y=".49632353">
                                    </rect>
                                    <path
                                        d="M8.43816176 10.4227941H12.40875M3.47492647 10.4227941h1.98529412M8.43816176 10.4227941c0 .8222496-.66650052 1.4888487-1.48875009 1.4889704-.82224958.0001218-1.48894741-.6662799-1.48919108-1.4885294-.00024354-.82224955.66605932-1.48904613 1.48830882-1.48941172.3950142-.00017541.77390973.15662032 1.05328902.4358755.2793793.27925518.43634333.65808102.43634333 1.05309522h0zM9.43080882 5.45955882H3.47492647M12.40875 5.45955882c0-.82224958-.6665005-1.48884866-1.4887501-1.48897042-.8222496-.00012176-1.48894741.66627989-1.48919108 1.48852944-.00024353.82224955.66605928 1.48904613 1.48830878 1.48941157.8224429 0 1.489267-.6665278 1.4896324-1.48897059z">
                                    </path>
                                </g>
                            </svg>
                            <a class="dropdown-toggle" aria-controls="advanced" aria-expanded="false"
                                data-toggle="collapse" role="button" href="#advanced">Advanced</a>
                        </li>
                    </ul>
                    <ul class="collapse subtitle" id="advanced">
                        <li id="customscript">
                            <a href="/en/admin/advanced/custom-script">Custom script</a>
                        </li>
                        <li id="experimental">
                            <a href="/en/admin/advanced/new-features">New features</a>
                        </li>
                        <li id="recaptcha">
                            <a href="/en/admin/advanced/recaptcha">reCAPTCHA</a>
                        </li>
                        <li id="deletemarketplace">
                            <a href="/en/admin/advanced/delete-marketplace">Delete marketplace</a>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- Bottom links for branded links -->
            <div class="sidenav-bottomLinks">
                <ul class="category-title">
                    <li>
                        <a rel="noopener" href="/en/admin/plan">Subscription</a>
                    </li>
                    <li>
                        <a rel="noopener" target="_blank" class="external-link"
                            href="https://help.sharetribe.com/?utm_source=marketplaceadminpanel&amp;utm_medium=referral&amp;utm_campaign=leftnavi">Help
                            Center</a>
                    </li>
                    <li>
                        <a rel="noopener" target="_blank" class="external-link"
                            href="https://www.sharetribe.com/academy/guide/?utm_source=marketplaceadminpanel&amp;utm_medium=referral&amp;utm_campaign=leftnavi">Marketplace
                            Guide</a>
                    </li>
                    <li>
                        <a rel="noopener" target="_blank" class="external-link"
                            href="https://www.sharetribe.com/products/go/updates?utm_source=marketplaceadminpanel&amp;utm_medium=referral&amp;utm_campaign=leftnavi">What's
                            new?</a>
                    </li>
                </ul>
            </div>
        </aside>

        <div class="dashboard-content-wrapper">
            <div class="dashboard-card user">
                <h1>Hi Paula, welcome to MadKraftz!</h1>
                <p>
                    Your marketplace is available at <a
                        href="https://madkraftz.sharetribe.com">https://madkraftz.sharetribe.com</a>. <br> Here in your
                    Admin panel, you can manage all your marketplace activity and settings. If you need help, you can
                    reach us through the chat in the bottom right corner.
                </p>
            </div>
            <div class="dashboard-card quicklinks">
                <h2>Quicklinks</h2>
                <div>
                    <p>Day-to-day:</p>
                    <ul>
                        <li>
                            <a href="/en/admin/users/manage-users?direction=desc&amp;sort=join_date">› Manage users</a>
                        </li>
                        <li>
                            <a href="/en/admin/listings/manage-listings?direction=desc&amp;sort=updated">› Manage
                                listings</a>
                        </li>
                    </ul>
                    <p>Transactions &amp; conversations:</p>
                    <ul>
                        <li>
                            <a
                                href="/en/admin/transactions-and-reviews/manage-transactions?direction=desc&amp;sort=last_activity">›
                                Manage transactions</a>
                        </li>
                        <li>
                            <a href="/en/admin/transactions-and-reviews/manage-reviews">› Manage reviews</a>
                        </li>
                        <li>
                            <a
                                href="/en/admin/transactions-and-reviews/view-conversations?direction=desc&amp;sort=last_activity">›
                                View conversations</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <p>Online payments:</p>
                    <ul>
                        <li>
                            <a href="/en/admin/payment-system/minimum-listing-price">› Minimum listing price</a>
                        </li>
                        <li>
                            <a href="/en/admin/payment-system/stripe">› Stripe setup and commission</a>
                        </li>
                        <li>
                            <a href="/en/admin/payment-system/paypal">› PayPal setup and commission</a>
                        </li>
                    </ul>
                    <p>What's new:</p>
                    <ul>
                        <li>
                            <a class="external-link" rel="noopener" target="_blank"
                                href="https://www.sharetribe.com/products/go/updates/?utm_source=marketplaceadminpanel&amp;utm_medium=referral&amp;utm_campaign=home">›
                                Sharetribe Go updates</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="dashboard-card guide">
                <div class="guideImg"></div>
                <div class="textWrapper">
                    <h2>The practical step-by-step guide to building a successful online marketplace business</h2>
                    <p>
                        <a class="external-link" rel="noopener" target="_blank"
                            href="https://www.sharetribe.com/academy/guide/?utm_source=marketplaceadminpanel&amp;utm_medium=referral&amp;utm_campaign=home">›
                            Read for free</a>
                    </p>
                </div>
            </div>
            <div class="dashboard-card academy">
                <h2>Marketplace Academy</h2>
                <p>Articles on building, running, and growing a marketplace business.</p>
                <p>Popular articles:</p>
                <ul>
                    <li>
                        <a class="external-link" rel="noopener" target="_blank"
                            href="https://www.sharetribe.com/academy/why-you-should-launch-your-marketplace-early/?utm_source=marketplaceadminpanel&amp;utm_medium=referral&amp;utm_campaign=home">›
                            Why you should launch your marketplace early</a>
                    </li>
                    <li>
                        <a class="external-link" rel="noopener" target="_blank"
                            href="https://www.sharetribe.com/academy/how-to-launch-your-marketplace/?utm_source=marketplaceadminpanel&amp;utm_medium=referral&amp;utm_campaign=home">›
                            How to launch your marketplace</a>
                    </li>
                    <li>
                        <a class="external-link" rel="noopener" target="_blank"
                            href="https://www.sharetribe.com/academy/measure-your-success-key-marketplace-metrics/?utm_source=marketplaceadminpanel&amp;utm_medium=referral&amp;utm_campaign=home">›
                            How to measure your success: the key marketplace metrics</a>
                    </li>
                </ul>
                <p>
                    <a class="external-link" rel="noopener" target="_blank"
                        href="https://www.sharetribe.com/academy/?utm_source=marketplaceadminpanel&amp;utm_medium=referral&amp;utm_campaign=home">›
                        Browse all Marketplace Academy articles</a>
                </p>
            </div>
            <div class="dashboard-card gopro">
                <div class="card-top">
                    <h2>Add a beautiful landing page to your marketplace</h2>
                    <p>Communicate your value proposition and engage your visitors the moment they arrive on your
                        marketplace. Add a beautifully designed landing page to your marketplace. $99 per month.</p>
                </div>
                <div class="card-bottom">
                    <ul>
                        <li>
                            <a target="_blank" rel="noopener" class="external-link"
                                href="https://www.sharetribe.com/products/go/landing-page/?utm_source=marketplaceadminpanel&amp;utm_medium=referral&amp;utm_campaign=home">›
                                Learn more about the Custom Landing Page</a>
                        </li>
                    </ul>
                </div>

            </div>
            <div class="dashboard-card help" data-intercom-target="Home Help">
                <div class="card-top">
                    <h2>Help!</h2>
                    <p>The support team's marketplace experts are here for you 7 days a week.</p>
                </div>
                <div class="card-bottom">
                    <ul>
                        <li>
                            <a show-intercom="true" href="#">› Send us a message</a>
                        </li>
                        <li>
                            <a rel="noopener" target="_blank" class="external-link"
                                href="https://help.sharetribe.com/?utm_source=marketplaceadminpanel&amp;utm_medium=referral&amp;utm_campaign=home">›
                                Browse Sharetribe Go's Help Center</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="dashboard-postscript">
                <small>
                    P.S. Have you listened to <a
                        href="https://www.sharetribe.com/twosided/?utm_source=marketplaceadminpanel&amp;utm_medium=referral&amp;utm_campaign=home"
                        class="external-link" rel="noopener" target="_blank">Two-sided: the marketplace podcast</a>?
                </small>
            </div>

        </div>
    </div>
</div>