import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './about/about.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { LeftMenuComponent } from './component/left-menu/left-menu.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    AboutComponent,
    HowItWorksComponent,
    PrivacyComponent,
    TermsComponent,
    LeftMenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class InformationModule { }
