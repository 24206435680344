<section class="marketplace-lander">
    <div class="coverimage">
        <figure class="marketplace-cover-small fluidratio"></figure>
        <div class="coverimage-fade">
            <figure class="marketplace-cover-small-fade fluidratio"></figure>
        </div>
    </div>
    <div class="title-container">
        <div class="title-header-wrapper">
            <div class="marketplace-title-header">
                <h1>Information about MadKraftz</h1>
            </div>
        </div>
    </div>
</section>
<article class="page-content">
    <div class="wrapper">
        <app-left-menu></app-left-menu>
        <div class="left-navi-section about-section">
            <div data-mercury="full" id="terms_page_content">
                <span style="background-color:#FFFFFF"></span>
                <h1>
                    <font color="#0a7951">MadKraftz Terms of Use</font>
                </h1>
                <p></p>
                <p>MadKraftz is a web service built on its own platform.</p>
                <h2>
                        <font color="#ff6600">Rights of Content</font>
                  </h2>
                <p>
                    The users themselves retain the right to all text, pictures and other content that they create in
                    the service. The users allow others to utilize the content in accordance with the nature of the
                    service and furthermore allow the service provider to file information and data and make changes
                    that are necessary for the service or the study, however other rights are not transferred from the
                    users, unless specifically otherwise agreed. The responsibility of the content lies with the user,
                    who has produced it to the service. The service provider has the right to remove any material when
                    it deems it necessary.
                </p>
                <h2><font color="#ff6600">Disclaimer</font></h2>
                <p>
                    The users are themselves responsible for their actions in the service and they should estimate the
                    reliability of other users
                    before dealing with them. The service provider can under no circumstances be liable for damage that
                    is caused to the user. The user may not store any information or data in the service, and expect it
                    to remain there.
                </p>
                <h2><font color="#ff6600">The Removal of a User</font></h2>
                <p>
                    The service provider has the right to remove any users from MadKraftz and terminate their right of
                    use of the service without any specific reason and without being liable for compensation.
                </p>
                <h2><font color="#ff6600">Applicable Jurisdiction</font></h2>
                <p>
                    The jurisdiction that is applicable in this service and these terms of use is that of United
                    Kingdom,
                    unless something else is required by binding law.</p>
                <p></p>
            </div>
        </div>
    </div>
</article>