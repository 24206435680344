<!-- <div class="auth-wrapper auth-v2">
    <div class="auth-inner row m-0">
      <a class="brand-logo" href="javascript:void(0);">
        <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" />
        <h2 class="brand-text text-primary ml-1">mRestaurant</h2></a
      >
      <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <img
            class="img-fluid"
            [src]="
              coreConfig.layout.skin === 'dark'
                ? 'assets/images/authenticate/login-dark.svg'
                : 'assets/images/authenticate/login.svg'
            "
            alt="Login V2"
          />
        </div>
      </div>
      <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
        <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
          <h2 class="card-title font-weight-bold mb-1">Welcome to mRestaurant! 👋</h2>
          <p class="card-text mb-2">Please sign-in to your account and start the adventure</p>
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@mudigal.com | Hari1@</small>
              </p>
              <p>
                <small class="mr-50"><span class="font-weight-bold">User:</span> user@mudigal.com | Hari1@</small>
              </p>
            </div>
            <span
              class="feather icon-help-circle auth-help-icon font-medium-3"
              placement="left"
              ngbTooltip="This is just for role based JWT authorization demo purpose"
            ></span>
          </ngb-alert>
  
          <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
            <div class="alert-body">
              <p>{{ error }}</p>
            </div>
          </ngb-alert>
  
          <form class="auth-login-form mt-2" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label class="form-label" for="login-email">Email</label>
              <input
                type="text"
                formControlName="email"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                placeholder="admin@demo.com"
                aria-describedby="login-email"
                autofocus=""
                tabindex="1"
              />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
              </div>
            </div>
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <label for="login-password">Password</label
                ><a routerLink="/pages/authentication/forgot-password-v2"><small>Forgot Password?</small></a>
              </div>
              <div class="input-group input-group-merge form-password-toggle">
                <input
                  [type]="passwordTextType ? 'text' : 'password'"
                  formControlName="password"
                  class="form-control form-control-merge"
                  [ngClass]="{ 'is-invalid error': submitted && f.password.errors }"
                  placeholder="············"
                  aria-describedby="login-password"
                  tabindex="2"
                />
                <div class="input-group-append">
                  <span class="input-group-text cursor-pointer"
                    ><i
                      class="feather font-small-4"
                      [ngClass]="{
                        'icon-eye-off': passwordTextType,
                        'icon-eye': !passwordTextType
                      }"
                      (click)="togglePasswordTextType()"
                    ></i
                  ></span>
                </div>
              </div>
              <div
                *ngIf="submitted && f.password.errors"
                class="invalid-feedback"
                [ngClass]="{ 'd-block': submitted && f.password.errors }"
              >
                <div *ngIf="f.password.errors.required">Password is required</div>
              </div>
            </div>
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input class="custom-control-input" id="remember-me" type="checkbox" tabindex="3" />
                <label class="custom-control-label" for="remember-me"> Remember Me</label>
              </div>
            </div>
            <button [disabled]="loading" class="btn btn-primary btn-block" tabindex="4" rippleEffect>
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Sign in
            </button>
          </form>
          <p class="text-center mt-2">
            <span>New on our platform?</span
            ><a routerLink="/register"><span>&nbsp;Create an account</span></a>
          </p>
          <div class="divider my-2">
            <div class="divider-text">or</div>
          </div>
          <div class="auth-footer-btn d-flex justify-content-center">
            <a class="btn btn-facebook" href="javascript:void(0)"><i data-feather="facebook"></i></a
            ><a class="btn btn-twitter white" href="javascript:void(0)"><i data-feather="twitter"></i></a
            ><a class="btn btn-google" href="javascript:void(0)"><i data-feather="mail"></i></a
            ><a class="btn btn-github" href="javascript:void(0)"><i data-feather="github"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
   -->


<section class="marketplace-lander">
  <div class="coverimage">
    <figure class="marketplace-cover-small fluidratio"></figure>
    <div class="coverimage-fade">
      <figure class="marketplace-cover-small-fade fluidratio"></figure>
    </div>
  </div>
  <div class="title-container">
    <div class="title-header-wrapper">
      <div class="marketplace-title-header">
        <h1>Log in to MadKraftz</h1>

      </div>
    </div>
  </div>
</section>

<article class="page-content">
  <div class="wrapper">

    <div class="login-form centered-section-narrow">
      <form accept-charset="UTF-8" method="post" [formGroup]="loginForm">
        <input name="utf8" type="hidden" value="✓">
        <label for="login">
          Email:
        </label>
        <input type="text" name="email" id="main_person_login" formControlName="email" class="text_field" autofocus="autofocus">
        <label for="password">
          Password:
        </label>
        <input type="password" name="password" formControlName="password" id="main_person_password" class="text_field">
        <button name="button" type="submit" class="send_button" id="main_log_in_button" (click)="onSubmit()">Log
          in</button>
        <div class="links">
          <a class="green_part_link" href="/en/signup">Create a new account</a>
          <br>
          <a id="password_forgotten_link" class="green_part_link" href="#">Forgot password</a>
        </div>
      </form>

    </div>
    <div id="password_forgotten">
      <div class="centered-section-narrow">
        <p>Enter your email address to reset your password.</p>
        <form action="/en/sessions/request_new_password" accept-charset="UTF-8" method="post"><input name="utf8"
            type="hidden" value="✓"><input type="hidden" name="authenticity_token"
            value="mbsJuNGs3GbugbMxKVM2kAf1OZiOmDIkizVZWxlBExwyzQhhGzWmpUMqa39jaluzJdt186ccBNURg+rgIuaZKg==">
          <label for="email">
            Email
          </label>
          <input type="text" name="email" id="email" class="text_field request_password">
          <button name="button" type="submit" class="send_button">Request new password</button>
        </form>

      </div>

    </div>

  </div>
</article>