import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit {
// Public
public emailVar!:any;
public coreConfig: any;
public forgotPasswordForm!: FormGroup;
public submitted = false;

// Private
private _unsubscribeAll: Subject<any>;

/**
 * Constructor
 *
 * @param {CoreConfigService} _coreConfigService
 * @param {FormBuilder} _formBuilder
 *
 */
constructor(private _formBuilder: FormBuilder) {
  this._unsubscribeAll = new Subject();
}

// convenience getter for easy access to form fields
get f() {
  return this.forgotPasswordForm.controls;
}

/**
 * On Submit
 */
onSubmit() {
  this.submitted = true;

  // stop here if form is invalid
  if (this.forgotPasswordForm.invalid) {
    return;
  }
}

// Lifecycle Hooks
// -----------------------------------------------------------------------------------------------------

/**
 * On init
 */
ngOnInit(): void {
  this.forgotPasswordForm = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]]
  });
}

/**
 * On destroy
 */
ngOnDestroy(): void {
  // Unsubscribe from all subscriptions
  this._unsubscribeAll.complete();
}
}
