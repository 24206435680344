<!-- <section id="info__Upcycling-terms__5" class="info__section--blank">
    <div class="info__content--single-column">
        <h1 class="info__title--single-column">Difference between upcycled, recycled, refurbished and reclaimed?</h1>

        <div class="info__paragraph--markdown">
            <p>When you recycle an item, it comes back as a completely different thing—you can't tell how it was used
                before.
                With upcycling, though, you can see what the original item used to be—and sometimes even how much better
                it is
                now than before!</p>

            <p>You might have heard the term "refurbished" before. That's another word for upcycling. Refurbishing means
                restoring something to working order again after it's been damaged or broken. When you refurbish an
                item, you're
                making sure it looks good as new while still being good to the environment!</p>

            <p>Finally there's "reclaimed wood," which means wood that has already been used for something else but has
                been
                taken apart and put back together again into new items like furniture.</p>

        </div>

        <a class="landing-button--ghost info__Upcycling-terms__5__landing-button--ghost"
            href="{{shopUrl}}/en/infos/about">Learn more</a>

    </div>
</section> -->

<div class="light-bg pt-5">
    <div class="container">
        <div class="row m-0">
            <div class="col-xl-8 col-lg-6 col-md-8 col-sm-12 d-flex justify-content-start">
                <!-- <div class="col-12"> -->
                    <h1 class="title text-start mr-5">A COUPLE OF<br />NEW WORDS!</h1>
                    <!-- <div class="d-flex justify-content-start p-0">
                        <div class="align-self-start"> -->
                            <div class="py-1 d-flex align-items-center">
                            <a class="landing-button--ghost landing-button"
                                href="/about&#35;upcycling" routerLink="/about" fragment="upcycling">LEARN
                                MORE</a>
                                </div>
                        <!-- </div>
                    </div> -->
                <!-- </div> -->
            </div>
            <!-- src="https://dl.dropboxusercontent.com/s/jn62tfu1x21v41m/infographic.gif?dl=1" -->
            <div class="row d-flex justify-content-center">
            <img class="col-lg-8 col-12 p-0" style="border-radius:5px;" src="/infographic.gif" alt="Difference">
        </div>
    </div></div>
</div>