<section class="marketplace-lander">
    <div class="coverimage">
        <figure class="marketplace-cover-small fluidratio"></figure>
        <div class="coverimage-fade">
            <figure class="marketplace-cover-small-fade fluidratio"></figure>
        </div>
    </div>
    <div class="title-container">
        <div class="title-header-wrapper">
            <div class="marketplace-title-header">
                <h1>Settings</h1>

            </div>
        </div>
    </div>
</section>
<article class="page-content">
    <div class="wrapper">

        <div class="left-navi">
            <a class="selected left-navi-link" href="/en/paulau1/settings" id="settings-tab-profile"
                title="Profile info">
                <div class="left-navi-link-icon ss-userfile"></div>
                <div class="left-navi-link-text">Profile info</div>
            </a>
            <a class="left-navi-link" href="/en/paulau1/settings/listings?sort=updated" id="settings-tab-listings"
                title="Listings">
                <div class="left-navi-link-icon ss-thumbnails"></div>
                <div class="left-navi-link-text">Listings</div>
            </a>
            <a class="left-navi-link" href="/en/paulau1/settings/transactions?direction=desc&amp;sort=last_activity"
                id="settings-tab-transactions" title="Transactions">
                <div class="left-navi-link-icon ss-coins"></div>
                <div class="left-navi-link-text">Transactions</div>
            </a>
            <a class="left-navi-link" href="/en/paulau1/settings/account" id="settings-tab-account" title="Account">
                <div class="left-navi-link-icon ss-lockfile"></div>
                <div class="left-navi-link-text">Account</div>
            </a>
            <a class="left-navi-link" href="/en/paulau1/settings/notifications" id="settings-tab-notifications"
                title="Notifications">
                <div class="left-navi-link-icon ss-callbell"></div>
                <div class="left-navi-link-text">Notifications</div>
            </a>
            <a class="left-navi-link" href="/en/paulau1/settings/payments" id="settings-tab-payments" title="Payments">
                <div class="left-navi-link-icon ss-moneybag"></div>
                <div class="left-navi-link-text">Payments</div>
            </a>
        </div>
        <div class="left-navi-dropdown toggle with-borders hidden-tablet" data-toggle=".left-navi-menu">
            <div class="icon-with-text ss-userfile"></div>
            <div class="text-with-icon">Profile info</div>
            <i class="icon-dropdown ss-dropdown"></i>
        </div>
        <div class="left-navi-menu toggle-menu hidden">
            <a href="/en/paulau1/settings" id="profile_left_navi_link" title="Profile info">
                <div class="icon-with-text ss-userfile"></div>
                <div class="text-with-icon">Profile info</div>
            </a>
            <a href="/en/paulau1/settings/listings?sort=updated" id="listings_left_navi_link" title="Listings">
                <div class="icon-with-text ss-thumbnails"></div>
                <div class="text-with-icon">Listings</div>
            </a>
            <a href="/en/paulau1/settings/transactions?direction=desc&amp;sort=last_activity"
                id="transactions_left_navi_link" title="Transactions">
                <div class="icon-with-text ss-coins"></div>
                <div class="text-with-icon">Transactions</div>
            </a>
            <a href="/en/paulau1/settings/account" id="account_left_navi_link" title="Account">
                <div class="icon-with-text ss-lockfile"></div>
                <div class="text-with-icon">Account</div>
            </a>
            <a href="/en/paulau1/settings/notifications" id="notifications_left_navi_link" title="Notifications">
                <div class="icon-with-text ss-callbell"></div>
                <div class="text-with-icon">Notifications</div>
            </a>
            <a href="/en/paulau1/settings/payments" id="payments_left_navi_link" title="Payments">
                <div class="icon-with-text ss-moneybag"></div>
                <div class="text-with-icon">Payments</div>
            </a>
        </div>

        <div class="left-navi-section settings-section" id="person_settings_form">
            <p>
                Some of this information is visible to all MadKraftz users
                <a href="/en/paulau1">in your profile page</a>.
            </p>
            <form class="edit_person" id="edit_person_lFjjFjGvSmpCs5mVLTVB6w" enctype="multipart/form-data"
                action="/en/paulau1" accept-charset="UTF-8" method="post" novalidate="novalidate"><input name="utf8"
                    type="hidden" value="✓"><input type="hidden" name="_method" value="patch"><input type="hidden"
                    name="authenticity_token"
                    value="pON8RBsVc2us22NUWtWkCC9IMy4c7XY6juxfzriTXEFcTk0ojZKMxjMAIIaDqzSJanVOCt+i1kigTjCcdF9yog=="><input
                    type="hidden" name="referer_form" id="referer_form" value="settings">
                <label for="person_given_name">First name</label>
                <input class="text_field" maxlength="30" size="30" type="text" value="Paula" name="person[given_name]"
                    id="person_given_name">
                <div class="inline-label-container">
                    <label class="inline" for="person_family_name">Last name</label>
                    <small>
                        (only first letter shown to other users)
                    </small>
                </div>
                <input class="text_field" maxlength="30" size="30" type="text" value="Ursu" name="person[family_name]"
                    id="person_family_name">
                <div class="inline-label-container">
                    <label class="inline" for="person_display_name">Display name</label>
                </div>
                <span class="alert-box-icon">
                    <i class="ss-info icon-fix"></i>
                </span>
                <small>
                    If you represent an organization, you can use its name as your display name. Display name is shown
                    to other users instead of your first and last name.
                </small>
                <input class="text_field" maxlength="100" size="100" type="text" name="person[display_name]"
                    id="person_display_name">
                <div class="inline-label-container">
                    <label class="inline" for="person_display_name">Username</label>
                </div>
                <span class="alert-box-icon">
                    <i class="ss-info icon-fix"></i>
                </span>
                <small>
                    Your username is used as part of the unique URL of your profile page. You can only use letters and
                    numbers for your username, without spaces. When you change your username, your URL will
                    automatically change and your previous URL will not be redirected. The old username will become
                    available for other users.
                </small>
                <input class="text_field" required="required" minlength="3" maxlength="20" valid_username="true"
                    remote="/en/people/paulau1/check_username_availability"
                    data-msg-remote="This username is invalid or unavailable"
                    data-msg-valid_username="This username is invalid or unavailable" size="20" type="text"
                    value="paulau1" name="person[username]" id="person_username">
                <div class="inline-label-container">
                    <label class="inline" for="person_phone_number">Phone number</label>
                    <small>
                        (not shown to other users)
                    </small>
                </div>
                <input class="text_field" maxlength="25" size="25" type="text" name="person[phone_number]"
                    id="person_phone_number">
                <div class="inline-label-container">
                    <label for="person_image">Profile picture</label>
                </div>
                <span class="alert-box-icon">
                    <i class="ss-info icon-fix"></i>
                </span>
                <small>
                    The profile picture should be in a square format (1:1 ratio), for example, 800x800 pixels.
                    Otherwise, it will be cropped to fit.
                </small>

                <input size="30" id="avatar_file" type="file" name="person[image]">
                <label class="input" for="person_description">About you</label>
                <span class="alert-box-icon">
                    <i class="ss-info icon-fix"></i>
                </span>
                <small>
                    You can <a id="markdown-popup-link" href="#">format your description using Markdown</a>.
                </small>
                <textarea class="update_profile_description_text_area" name="person[description]"
                    id="person_description"
                    style="overflow: hidden; word-wrap: break-word; resize: horizontal; height: 120px;"></textarea>
                <input type="hidden" name="person[location][address]" id="person_location_address">
                <input type="hidden" name="person[location][google_address]" id="person_location_google_address">
                <input type="hidden" name="person[location][latitude]" id="person_location_latitude">
                <input type="hidden" name="person[location][longitude]" id="person_location_longitude">
                <div class="inline-label-container"></div>
                <div class="inline-label-container">
                    <label class="inline" for="person_custom_fields_0">Delivery*</label>
                    <small>(shown in your public profile)</small>
                </div>
                <input type="hidden" name="person[custom_field_values_attributes][][id]"
                    id="person_custom_field_values_attributes__id">
                <input type="hidden" name="person[custom_field_values_attributes][][type]"
                    id="person_custom_field_values_attributes__type" value="TextFieldValue">
                <input type="hidden" name="person[custom_field_values_attributes][][custom_field_id]"
                    id="person_custom_field_values_attributes__custom_field_id" value="181210">
                <input type="text" name="person[custom_field_values_attributes][][text_value]"
                    id="person_custom_fields_0" class="required" input_group_id="person_custom_fields_0">

                <div class="inline-label-container"></div>
                <div class="inline-label-container">
                    <label class="inline" for="person_custom_fields_1">Shop Policy*</label>
                    <small>(shown in your public profile)</small>
                </div>
                <input type="hidden" name="person[custom_field_values_attributes][][id]"
                    id="person_custom_field_values_attributes__id">
                <input type="hidden" name="person[custom_field_values_attributes][][type]"
                    id="person_custom_field_values_attributes__type" value="TextFieldValue">
                <input type="hidden" name="person[custom_field_values_attributes][][custom_field_id]"
                    id="person_custom_field_values_attributes__custom_field_id" value="181206">
                <input type="text" name="person[custom_field_values_attributes][][text_value]"
                    id="person_custom_fields_1" class="required" input_group_id="person_custom_fields_1">

                <div class="inline-label-container"></div>
                <div class="inline-label-container">
                    <label class="inline" for="person_custom_fields_2">Return and Exchange policy*</label>
                    <small>(shown in your public profile)</small>
                </div>
                <input type="hidden" name="person[custom_field_values_attributes][][id]"
                    id="person_custom_field_values_attributes__id">
                <input type="hidden" name="person[custom_field_values_attributes][][type]"
                    id="person_custom_field_values_attributes__type" value="TextFieldValue">
                <input type="hidden" name="person[custom_field_values_attributes][][custom_field_id]"
                    id="person_custom_field_values_attributes__custom_field_id" value="181208">
                <input type="text" name="person[custom_field_values_attributes][][text_value]"
                    id="person_custom_fields_2" class="required" input_group_id="person_custom_fields_2">

                <div class="inline-label-container"></div>
                <div class="inline-label-container">
                    <label class="inline" for="person_custom_fields_3">VAT ID*</label>
                    <small>(shown in your public profile)</small>
                </div>
                <input type="hidden" name="person[custom_field_values_attributes][][id]"
                    id="person_custom_field_values_attributes__id">
                <input type="hidden" name="person[custom_field_values_attributes][][type]"
                    id="person_custom_field_values_attributes__type" value="TextFieldValue">
                <input type="hidden" name="person[custom_field_values_attributes][][custom_field_id]"
                    id="person_custom_field_values_attributes__custom_field_id" value="181209">
                <input type="text" name="person[custom_field_values_attributes][][text_value]"
                    id="person_custom_fields_3" class="required" input_group_id="person_custom_fields_3">

                <button name="button" type="submit" class="send_button">Save information</button>
            </form>
        </div>
        <div class="lightbox" id="markdown-help-popup">
            <div class="lightbox-content">
                <a class="close lightbox-x" href="#" id="close_x">
                    <i class="ss-delete"></i>
                </a>
                <h2>Formatting help using Markdown</h2>
                <p>Markdown is a simple way to format text that looks great on any device. It doesn’t do anything fancy
                    like change the font size, color, or type — just the essentials, using keyboard symbols you already
                    know.</p>
                <table class="markdown-help-table">
                    <tbody>
                        <tr>
                            <th>Formatting</th>
                            <th>Result</th>
                        </tr>
                        <tr>
                            <td># Your H1 text goes here<br>## Your H2 text goes here<br>### Your H3 text goes here
                            </td>
                            <td>
                                <h1>Your H1 text goes here</h1>
                                <br>
                                <h2>Your H2 text goes here</h2>
                                <br>
                                <h3>Your H3 text goes here</h3>
                            </td>
                        </tr>
                        <tr>
                            <td>*italic*</td>
                            <td>
                                <i>italic</i>
                            </td>
                        </tr>
                        <tr>
                            <td>**bold**</td>
                            <td>
                                <b>bold</b>
                            </td>
                        </tr>
                        <tr>
                            <td>_underline_</td>
                            <td>
                                <u>underline</u>
                            </td>
                        </tr>
                        <tr>
                            <td>~~strikethrough~~</td>
                            <td>
                                <!-- <strike>strikethrough</strike> -->
                            </td>
                        </tr>
                        <tr>
                            <td>[Inline link](https://www.example.com)</td>
                            <td>
                                <a href="https://www.example.com" target="_blank">Inline link</a>
                            </td>
                        </tr>
                        <tr>
                            <td>- List<br>- List<br>- List
                            </td>
                            <td>
                                <ul>
                                    <li>
                                        List
                                        <br>
                                        <br>
                                    </li>
                                    <li>
                                        List
                                        <br>
                                        <br>
                                    </li>
                                    <li>
                                        List
                                        <br>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1. One
                                <br>
                                2. Two
                                <br>
                                3. Three
                            </td>
                            <td>
                                <ol>
                                    <li>
                                        One
                                        <br>
                                        <br>
                                    </li>
                                    <li>
                                        Two
                                        <br>
                                        <br>
                                    </li>
                                    <li>
                                        Three
                                        <br>
                                        <br>
                                    </li>
                                </ol>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>


    </div>
</article>