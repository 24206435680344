import { Component, ViewChild } from '@angular/core';
import { PlyrComponent } from 'ngx-plyr';

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.css', './../home.component.css']
})
export class PresentationComponent {

  // get the component instance to have access to plyr instance
  @ViewChild(PlyrComponent)
  plyr!: PlyrComponent;

  // or get it from plyrInit event
  player!: Plyr;

  videoSources: Plyr.Source[] = [
    {
      src: 'v6nulrF2jNc',
      provider: 'youtube',
    },
  ];
  plyrWidthStyle: string = '100%';
  plyrHeightStyle: string = '100%';

  played(event: Plyr.PlyrEvent) {
    // this.plyrWidthStyle = '80%';
    // this.plyrHeightStyle = '80%';
    this.plyrWidthStyle = '100%';
    this.plyrHeightStyle = '100%';
    this.plyr.plyrControlsShown;
  }

  paused(event: Plyr.PlyrEvent) {
    this.plyrWidthStyle = '100%';
    this.plyrHeightStyle = '100%';
    this.plyr.plyrControlsHidden;
  }
}

