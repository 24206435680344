<section class="marketplace-lander">
    <div class="coverimage">
        <figure class="marketplace-cover-small fluidratio"></figure>
        <div class="coverimage-fade">
            <figure class="marketplace-cover-small-fade fluidratio"></figure>
        </div>
    </div>
    <div class="title-container">
        <div class="title-header-wrapper">
            <div class="marketplace-title-header">
                <h1>
                    Settings
                    -
                    Listings
                </h1>

            </div>
        </div>
    </div>
</section>
<article class="page-content">
    <div class="wrapper">

        <div class="left-navi">
            <a class="left-navi-link" href="/en/paulau1/settings" id="settings-tab-profile" title="Profile info">
                <div class="left-navi-link-icon ss-userfile"></div>
                <div class="left-navi-link-text">Profile info</div>
            </a>
            <a class="selected left-navi-link" href="/en/paulau1/settings/listings?sort=updated"
                id="settings-tab-listings" title="Listings">
                <div class="left-navi-link-icon ss-thumbnails"></div>
                <div class="left-navi-link-text">Listings</div>
            </a>
            <a class="left-navi-link" href="/en/paulau1/settings/transactions?direction=desc&amp;sort=last_activity"
                id="settings-tab-transactions" title="Transactions">
                <div class="left-navi-link-icon ss-coins"></div>
                <div class="left-navi-link-text">Transactions</div>
            </a>
            <a class="left-navi-link" href="/en/paulau1/settings/account" id="settings-tab-account" title="Account">
                <div class="left-navi-link-icon ss-lockfile"></div>
                <div class="left-navi-link-text">Account</div>
            </a>
            <a class="left-navi-link" href="/en/paulau1/settings/notifications" id="settings-tab-notifications"
                title="Notifications">
                <div class="left-navi-link-icon ss-callbell"></div>
                <div class="left-navi-link-text">Notifications</div>
            </a>
            <a class="left-navi-link" href="/en/paulau1/settings/payments" id="settings-tab-payments" title="Payments">
                <div class="left-navi-link-icon ss-moneybag"></div>
                <div class="left-navi-link-text">Payments</div>
            </a>
        </div>
        <div class="left-navi-dropdown toggle with-borders hidden-tablet" data-toggle=".left-navi-menu">
            <div class="icon-with-text ss-thumbnails"></div>
            <div class="text-with-icon">Listings</div>
            <i class="icon-dropdown ss-dropdown"></i>
        </div>
        <div class="left-navi-menu toggle-menu hidden">
            <a href="/en/paulau1/settings" id="profile_left_navi_link" title="Profile info">
                <div class="icon-with-text ss-userfile"></div>
                <div class="text-with-icon">Profile info</div>
            </a>
            <a href="/en/paulau1/settings/listings?sort=updated" id="listings_left_navi_link" title="Listings">
                <div class="icon-with-text ss-thumbnails"></div>
                <div class="text-with-icon">Listings</div>
            </a>
            <a href="/en/paulau1/settings/transactions?direction=desc&amp;sort=last_activity"
                id="transactions_left_navi_link" title="Transactions">
                <div class="icon-with-text ss-coins"></div>
                <div class="text-with-icon">Transactions</div>
            </a>
            <a href="/en/paulau1/settings/account" id="account_left_navi_link" title="Account">
                <div class="icon-with-text ss-lockfile"></div>
                <div class="text-with-icon">Account</div>
            </a>
            <a href="/en/paulau1/settings/notifications" id="notifications_left_navi_link" title="Notifications">
                <div class="icon-with-text ss-callbell"></div>
                <div class="text-with-icon">Notifications</div>
            </a>
            <a href="/en/paulau1/settings/payments" id="payments_left_navi_link" title="Payments">
                <div class="icon-with-text ss-moneybag"></div>
                <div class="text-with-icon">Payments</div>
            </a>
        </div>

        <div class="left-navi-section">
            <h2>Listings</h2>
            <form action="" accept-charset="UTF-8" method="get"><input name="utf8" type="hidden" value="✓">
                <div class="row">
                    <div class="col-6">
                        <input type="text" name="q" id="q" placeholder="Search for a listing title">
                    </div>
                    <div class="col-3 status-select-wrapper">
                        <div class="status-select-button">
                            All statuses
                        </div>
                        <div class="status-select-dropdown">
                            <div class="status-select-line reset" data-status="all">
                                All statuses
                            </div>
                            <div class="status-select-line" data-status="open">
                                <input type="checkbox" name="status[]" id="status-open" value="open">
                                Open
                            </div>
                            <div class="status-select-line" data-status="closed">
                                <input type="checkbox" name="status[]" id="status-closed" value="closed">
                                Closed
                            </div>
                            <div class="status-select-line" data-status="expired">
                                <input type="checkbox" name="status[]" id="status-expired" value="expired">
                                Expired
                            </div>
                            <div class="status-select-line" data-status="approval_pending">
                                <input type="checkbox" name="status[]" id="status-approval_pending"
                                    value="approval_pending">
                                Pending
                            </div>
                            <div class="status-select-line" data-status="approval_rejected">
                                <input type="checkbox" name="status[]" id="status-approval_rejected"
                                    value="approval_rejected">
                                Rejected
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <input type="submit" name="commit" value="Search" class="button search-button"
                            data-disable-with="Search">
                        <a href="/en/paulau1/settings/listings?sort=updated">Show all</a>
                    </div>
                </div>
            </form>

            <span id="admin_listings_count">No listing found</span>
            <table id="admin_listings">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>
                            <a class="sort-link"
                                href="/en/paulau1/settings/listings?direction=asc&amp;page=1&amp;sort=started">
                                <div class="sort-text-wrapper">
                                    <div class="sort-text">
                                        Created
                                    </div>
                                </div>
                            </a>
                        </th>
                        <th>
                            <a class="sort-link"
                                href="/en/paulau1/settings/listings?direction=asc&amp;page=1&amp;sort=updated">
                                <div class="sort-text-wrapper">
                                    <div class="sort-text">
                                        Updated
                                    </div>
                                </div>
                            </a>
                        </th>
                        <th>Category</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
            <div class="row">
                <div class="col-12">

                </div>
            </div>
            <div class="lightbox" id="listing_popup">
                <div class="lightbox-content">
                    <a class="close lightbox-x" href="#" id="close_x">
                        <i class="ss-delete"></i>
                    </a>
                    <div id="listing-form"></div>

                </div>
            </div>

        </div>

    </div>
</article>