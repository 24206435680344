import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.css', './../home.component.css']
})
export class MissionComponent implements OnInit {

  shopUrl = environment.shopUrl;
  constructor() { }

  ngOnInit(): void {
  }

}
