<div class="light-bg">
    <div class="container">
        <div class="row py-3 m-0">
            <div class="col-lg-4 col-sm-12 col-12 d-flex">
                <h1 class="title align-self-center">OUR CURATED<br/>CREATIONS</h1>
            </div>
            <div class="col-lg-2 col-sm-3 col-12 p-2">
                <div class="curated">
                    <a href="{{shopUrl}}/?category=furniture">
                        <div class="curated-content"
                            style="background-image: url('/assets/images/curated/furniture.png')">
                            <h3 class="curated-title">FURNITURE</h3>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-sm-3 col-12 p-2">
                <div class="curated">
                    <a href="{{shopUrl}}/?category=lighting">
                        <div class="curated-content"
                            style="background-image: url('/assets/images/curated/lighting.png')">
                            <h3 class="curated-title">LIGHTING</h3>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-sm-3 col-12 p-2">
                <div class="curated">
                    <a href="{{shopUrl}}/?category=art-and-decor">
                        <div class="curated-content"
                            style="background-image: url('/assets/images/curated/gifts.png')">
                            <h3 class="curated-title text-center">ART<br/>&<br/>DECOR</h3>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-sm-3 col-12 p-2">
                <div class="curated">
                    <a href="{{shopUrl}}/?category=under-200">
                        <div class="curated-content" style="background-image: url('/assets/images/curated/under200.png')">
                            <h3 class="curated-title text-center">UNDER<br />200</h3>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
